import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  cancelInvitationInServer,
  createInvitationInServer,
  getInvitationsFromServer,
} from '../../../api/invitations/requests'
import {
  cancelInvitationAction,
  CancelInvitationPayload,
  createInvitationAction,
  CreateInvitationPayload,
  FetchInvitationsPayload,
  fetchInvitationsAction,
} from './actions'
import {
  addInvitationAction,
  changeInvitationStatusAction,
  setInvitationsAction,
} from './slice'

function* fetchInvitationsAsync(action: {
  payload: FetchInvitationsPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search } =
    action.payload
  try {
    const response = yield call(() =>
      getInvitationsFromServer(offset, limit, search)
    )
    yield put(setInvitationsAction(response.data))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* createInvitationAsync(action: {
  payload: CreateInvitationPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => createInvitationInServer(rest))
    yield put(addInvitationAction(response))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

function* cancelInvitationAsync(action: {
  payload: CancelInvitationPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, invitationId } = action.payload
  try {
    yield call(() => cancelInvitationInServer(invitationId))
    yield put(
      changeInvitationStatusAction({ invitationId, status: 'cancelled' })
    )
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchInvitationsSaga(): SagaIterator {
  yield takeLatest(fetchInvitationsAction, fetchInvitationsAsync)
  yield takeLatest(createInvitationAction, createInvitationAsync)
  yield takeLatest(cancelInvitationAction, cancelInvitationAsync)
}
