import { createAction } from '@reduxjs/toolkit'
import { CreateNamespaceRequestPayload } from '../../../api/namespaces/models'

const NAMESPACE_PREFIX = 'NAMESPACE'

export interface CreateNamespacePayload extends CreateNamespaceRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface FetchNamespacesPayload {
  offset: number
  limit: number
  search: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

/** actions */

export const fetchNamespacesAction = createAction<FetchNamespacesPayload>(
  `${NAMESPACE_PREFIX}/API/FETCH_NAMESPACES`
)

export const createNamespaceAction = createAction<CreateNamespacePayload>(
  `${NAMESPACE_PREFIX}/API/CREATE_NAMESPACE`
)
