import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { toggleUserPropertiesAction } from '../../store/slices/userlist/actions'
import { UserObj } from '../../store/slices/userlist/slice'
import { useAppDispatch } from '../../store/hooks'

type PayloadObj = {
  enabled?: boolean
  admin?: boolean
}

interface ToggleSwitchObj {
  user: UserObj
  payload: PayloadObj
}

const ToggleSwitch = ({ user, payload }: ToggleSwitchObj) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  const handleClickOpen = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setOpen(true)
  }

  const handleConfirm = () => {
    dispatch(
      toggleUserPropertiesAction({
        userId: user.id,
        enabled: payload.enabled === undefined ? user.enabled : payload.enabled,
        admin: payload.admin === undefined ? user.admin : payload.admin,
        postSuccessHandler: () => {
          enqueueSnackbar(
            'User updated successfully!',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          enqueueSnackbar('Failed to update user!', ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Switch
        checked={payload.enabled !== undefined ? user.enabled : user.admin}
        onChange={handleClickOpen}
        color="secondary"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm the action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {payload.enabled !== undefined
              ? `Do you really want to ${
                  user.enabled ? 'disable' : 'enable'
                } the user "${user.email}"?`
              : `Do you really want to ${
                  user.admin ? 'disable' : 'enable'
                } the admin status of the user "${user.email}"?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ToggleSwitch
