import { createAction } from '@reduxjs/toolkit'
import { ProfileObj } from './slice'

const PROFILE_PREFIX = 'PROFILE'

export interface UpdateProfilePayload {
  profile: ProfileObj
  postSuccessHandler: () => void
  errorHandler: () => void
}

export const fetchProfileAction = createAction(
  `${PROFILE_PREFIX}/API/GET_PROFILE`
)

export const updateProfileAction = createAction<UpdateProfilePayload>(
  `${PROFILE_PREFIX}/API/UPDATE_PROFILE`
)
