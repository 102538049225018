import { useKeycloak } from '@react-keycloak/web'
import { Navigate, Outlet } from 'react-router-dom'
import { LOCAL_PROFILE_ROUTE } from '../../configs/routes'

const ProtectedGateway = (): JSX.Element => {
  const { keycloak } = useKeycloak()

  return keycloak.authenticated ? (
    <Navigate to={LOCAL_PROFILE_ROUTE} />
  ) : (
    <Outlet />
  )
}

export default ProtectedGateway
