import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getRolesFromServer,
  createRoleInServer,
  getPermissionsFromServer as getPermissionsFromServer,
  createPermissionInServer,
  removePermissionInServer,
  getRoleUsersFromServer,
  addRoleUserInServer,
  removeRoleUserInServer,
} from '../../../api/roles/requests'
import {
  CreateRolePayload,
  FetchRolesPayload,
  FetchPermissionsPayload,
  createRoleAction,
  fetchRolesAction,
  fetchPermissionsAction,
  createPermissionAction,
  CreatePermissionPayload,
  removePermissionAction,
  RemovePermissionPayload,
  fetchRoleUsersAction,
  FetchRoleUsersPayload,
  removeRoleUsersAction,
  addRoleUsersAction,
} from './actions'
import {
  addRoleAction,
  setRolesAction,
  setPermissionsAction,
  addPermissionAction,
  setRoleUsersAction,
} from './slice'
import { AddRemoveEntityUserPayload } from '../models'

function* fetchRolesAsync(action: {
  payload: FetchRolesPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search } =
    action.payload
  try {
    const response = yield call(() => getRolesFromServer(offset, limit, search))
    yield put(setRolesAction(response.data))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* fetchPermissionsAsync(action: {
  payload: FetchPermissionsPayload
}): SagaIterator {
  const { errorHandler, roleId } = action.payload
  try {
    const response = yield call(() => getPermissionsFromServer(roleId))
    const actionPayload = {
      roleId,
      permissions: response,
    }
    yield put(setPermissionsAction(actionPayload))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* fetchRoleUsersAsync(action: {
  payload: FetchRoleUsersPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search, roleId } =
    action.payload
  try {
    const response = yield call(() =>
      getRoleUsersFromServer(offset, limit, search, roleId)
    )
    const actionPayload = {
      roleId,
      users: response.data,
    }
    yield put(setRoleUsersAction(actionPayload))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* addRoleUsersAsync(action: {
  payload: AddRemoveEntityUserPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, userId, entityId } = action.payload
  try {
    yield call(() => addRoleUserInServer({ userId, entityId }))
    yield call(() => postSuccessHandler())
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* removeRoleUsersAsync(action: {
  payload: AddRemoveEntityUserPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, userId, entityId } = action.payload
  try {
    yield call(() => removeRoleUserInServer({ userId, entityId }))
    yield call(() => postSuccessHandler())
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* createRoleAsync(action: {
  payload: CreateRolePayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => createRoleInServer(rest))
    yield put(addRoleAction(response))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

function* createPermissionAsync(action: {
  payload: CreatePermissionPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => createPermissionInServer(rest))
    yield put(addPermissionAction(response))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

function* removePermissionAsync(action: {
  payload: RemovePermissionPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => removePermissionInServer(rest))
    if (response.status === 204) {
      yield call(postSuccessHandler)
    } else {
      yield call(errorHandler)
    }
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchRolesSaga(): SagaIterator {
  yield takeLatest(fetchRolesAction, fetchRolesAsync)
  yield takeLatest(createRoleAction, createRoleAsync)
  yield takeLatest(fetchPermissionsAction, fetchPermissionsAsync)
  yield takeLatest(createPermissionAction, createPermissionAsync)
  yield takeLatest(removePermissionAction, removePermissionAsync)
  yield takeLatest(fetchRoleUsersAction, fetchRoleUsersAsync)
  yield takeLatest(addRoleUsersAction, addRoleUsersAsync)
  yield takeLatest(removeRoleUsersAction, removeRoleUsersAsync)
}
