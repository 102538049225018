import {
  SERVER_PROFILE_ENDPOINT,
  SERVER_UPDATE_PASSWORD_ENDPOINT,
} from '../../configs/endpoints'
import { axioService, GET, POST, PUT } from '../../services/axios'
import { ProfileObj } from '../../store/slices/profile/slice'
import { mapProfileFromServer } from './mappings'

export const getProfileFromServer = async (): Promise<ProfileObj> => {
  const response = await axioService(GET, SERVER_PROFILE_ENDPOINT, {}, true)
  return mapProfileFromServer(response.data)
}

export const updateServerProfile = async (
  requested: ProfileObj
): Promise<ProfileObj> => {
  const response = await axioService(
    PUT,
    SERVER_PROFILE_ENDPOINT,
    {
      first_name: requested.firstName,
      last_name: requested.lastName,
    },
    true
  )
  return mapProfileFromServer(response.data)
}

export const changeProfilePasswordInServer = async (
  requested_password: string
) => {
  await axioService(
    POST,
    SERVER_UPDATE_PASSWORD_ENDPOINT,
    {
      password: requested_password,
    },
    true
  )
}
