import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getNamespacesFromServer,
  createNamespaceInServer,
} from '../../../api/namespaces/requests'
import {
  CreateNamespacePayload,
  FetchNamespacesPayload,
  createNamespaceAction,
  fetchNamespacesAction,
} from './actions'
import { addNamespaceAction, setNamespacesAction } from './slice'

function* fetchNamespacesAsync(action: {
  payload: FetchNamespacesPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search } =
    action.payload
  try {
    const response = yield call(() =>
      getNamespacesFromServer(offset, limit, search)
    )
    yield put(setNamespacesAction(response.data))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* createNamespaceAsync(action: {
  payload: CreateNamespacePayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => createNamespaceInServer(rest))
    yield put(addNamespaceAction(response))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchNamespacesSaga(): SagaIterator {
  yield takeLatest(fetchNamespacesAction, fetchNamespacesAsync)
  yield takeLatest(createNamespaceAction, createNamespaceAsync)
}
