import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../..'

/** interface to define the Profile object */
export interface ProfileObj {
  email: string
  firstName: string
  lastName: string
  groupId: string
  groupName: string
}

/** interface to declare profile state */
type ProfileState = ProfileObj

/** the profile reducer name */
export const reducerName = 'profile'

/** initial reducer state */
export const initialProfileState: ProfileState = {
  email: '',
  firstName: '',
  lastName: '',
  groupId: '',
  groupName: '',
}

/** slice */

const profileSlice = createSlice({
  name: reducerName,
  initialState: initialProfileState,
  reducers: {
    setProfile(state, action: PayloadAction<ProfileObj>) {
      return action.payload
    },
    resetProfile() {
      return initialProfileState
    },
  },
})

/** actions */

/** export the profile reducer actions */
export const {
  setProfile: setProfileAction,
  resetProfile: resetProfileAction,
} = profileSlice.actions

/** selectors */

/** returns the profile in state
 * @param {Store} state - the redux store
 * @return { ProfileObj} - the existing profile
 */
export function selectProfile(state: Store): ProfileObj {
  return state[reducerName]
}

/** export the profile default reducer */
export default profileSlice.reducer
