import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link, useMatch } from 'react-router-dom'
import { StyledListItemButton } from './elements'

type TargetOptions = '_self' | '_blank'

export interface NavItemProps {
  label: string
  icon: JSX.Element
  link: string
  target?: TargetOptions
  isBoldFont?: boolean
  shouldIncludeModule: boolean
}

const NavItem: React.FC<NavItemProps> = ({
  label,
  link,
  icon,
  target = '_self',
  isBoldFont = false,
}: NavItemProps): JSX.Element => {
  const isActive = useMatch(link)
  const props = { component: Link, to: link, target: target }

  return isActive ? (
    <StyledListItemButton {...props}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          fontWeight: isBoldFont ? 'bold' : 'inherit',
        }}
      />
    </StyledListItemButton>
  ) : (
    <ListItemButton {...props}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          fontWeight: isBoldFont ? 'bold' : 'inherit',
        }}
      />
    </ListItemButton>
  )
}

export default NavItem
