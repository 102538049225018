import * as yup from 'yup'
import { useFormik } from 'formik'
import {
  StyledButtonWrapper,
  StyledCircularProgress,
  StyledForm,
  StyledHeaderWrapper,
  StyledTextInput,
  StyledSubtitle,
} from './elements'
import { Button, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { selectProfile, ProfileObj } from '../../store/slices/profile/slice'
import { updateProfileAction } from '../../store/slices/profile/actions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

type FormValues = ProfileObj

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
})

const ProfileUpdateForm = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const currentProfileInfo = useAppSelector(selectProfile)

  const formik = useFormik<FormValues>({
    initialValues: currentProfileInfo,
    validationSchema: validationSchema,
    onSubmit: async (values: FormValues) => {
      setLoading(true)
      dispatch(
        updateProfileAction({
          profile: values,
          postSuccessHandler: () => {
            setLoading(false)
            enqueueSnackbar(
              'Profile updated successfully',
              SUCCESS_NOTIFICATION_OPTIONS
            )
          },
          errorHandler: () => {
            setLoading(false)
            enqueueSnackbar(
              'Failed to save profile',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  useMemo(() => {
    formik.setValues(currentProfileInfo)
  }, [currentProfileInfo])

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <StyledHeaderWrapper>
        <Typography variant="h4" noWrap={true}>
          Personal Info
        </Typography>
        <StyledSubtitle variant="subtitle1" noWrap={true}>
          Update your profile information
        </StyledSubtitle>
      </StyledHeaderWrapper>
      <StyledTextInput
        id="email"
        name="email"
        label="Email"
        size="small"
        margin="normal"
        disabled
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <StyledTextInput
        id="groupName"
        name="groupName"
        label="Organization"
        size="small"
        margin="normal"
        disabled
        value={formik.values.groupName}
        onChange={formik.handleChange}
        error={formik.touched.groupName && Boolean(formik.errors.groupName)}
        helperText={formik.touched.groupName && formik.errors.groupName}
      />
      <StyledTextInput
        id="firstName"
        name="firstName"
        label="First Name"
        size="small"
        margin="normal"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <StyledTextInput
        id="lastName"
        name="lastName"
        label="Last Name"
        size="small"
        margin="normal"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />
      <StyledButtonWrapper>
        <Button
          disabled={
            loading ||
            (currentProfileInfo.firstName === formik.values.firstName &&
              currentProfileInfo.lastName === formik.values.lastName)
          }
          color="secondary"
          variant="contained"
          type="submit"
        >
          {loading && <StyledCircularProgress size={18} />}Save
        </Button>
      </StyledButtonWrapper>
    </StyledForm>
  )
}

export default ProfileUpdateForm
