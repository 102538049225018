import { Button } from '@mui/material'
import { useModal } from '../../hooks/useModal'
import { GroupManageModalBody } from '../GroupManageModalBody'
import { Modal } from '../Modal'
import { resetCurrentGroupAction } from '../../store/slices/groupUser/slice'
import { initializeGroupUserStoreAction } from '../../store/slices/groupUser/actions'
import { Snackbar } from '../SnackbarConfigurator'
import { useAppDispatch } from '../../store/hooks'

interface ManageButtonPayload {
  groupName: string
  groupId: string
}

const GroupManageButton = ({ groupName, groupId }: ManageButtonPayload) => {
  const dispatch = useAppDispatch()
  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  const openModalAndInitializeStore = () => {
    dispatch(
      initializeGroupUserStoreAction({
        groupId,
        groupName,
        errorHandler: () => {
          Snackbar.error('An error occurred while fetching users!')
        },
      })
    )
    openModalHandler()
  }

  const closeModalAndClearStore = () => {
    closeModalHandler()
    dispatch(resetCurrentGroupAction())
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={openModalAndInitializeStore}
        size="small"
      >
        Manage
      </Button>
      <Modal
        open={modalStatus}
        onCancel={closeModalAndClearStore}
        title={groupName}
        content={
          <GroupManageModalBody groupId={groupId} groupName={groupName} />
        }
        maxWidth="lg"
        fullWidth={true}
      />
    </>
  )
}

export default GroupManageButton
