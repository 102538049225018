import { Button, Stack } from '@mui/material'
import { StyledActionsWrapper } from './elements'
import { ControlCamera, SupervisedUserCircle } from '@mui/icons-material'
import { Modal } from '../Modal'
import { useModal } from '../../hooks/useModal'
import { RoleObj } from '../../store/slices/roles/slice'
import { PermissionTable } from '../PermissionTable'
import { RoleUsersTable } from '../RoleUsersTable'

interface RoleTableActionsProps {
  role: RoleObj
}

const RoleTableActions: React.FC<RoleTableActionsProps> = ({
  role: role,
}: RoleTableActionsProps) => {
  const {
    modalStatus: userListModalStatus,
    openModalHandler: openUserListModalHandler,
    closeModalHandler: closeUserListModalHandler,
  } = useModal()
  const {
    modalStatus: permissionListModalStatus,
    openModalHandler: openPermissionListModalHandler,
    closeModalHandler: closePermissionListModalHandler,
  } = useModal()

  return (
    <StyledActionsWrapper>
      <Stack direction="row" spacing={1}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<SupervisedUserCircle />}
          onClick={openUserListModalHandler}
        >
          Users
        </Button>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<ControlCamera />}
          onClick={openPermissionListModalHandler}
        >
          Permissions
        </Button>
      </Stack>
      <Modal
        open={userListModalStatus}
        onCancel={closeUserListModalHandler}
        title={role.name}
        content={<RoleUsersTable roleId={role.id} roleName={role.name} />}
        maxWidth="lg"
        fullWidth={true}
      />
      <Modal
        open={permissionListModalStatus}
        onCancel={closePermissionListModalHandler}
        title={role.name}
        content={<PermissionTable roleId={role.id} />}
        maxWidth="lg"
        fullWidth={true}
      />
    </StyledActionsWrapper>
  )
}

export default RoleTableActions
