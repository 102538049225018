import { EntityObj } from '../../store/slices/entity/slice'
import { ServerEntityObj } from './models'

export const mapEntityFromServer = (requested: ServerEntityObj): EntityObj => {
  return {
    id: requested.id,
    createdAt: requested.created_at,
    deleted: requested.deleted,
    updatedAt: requested.updated_at || '',
    createdBy: requested.created_by || '',
    updatedBy: requested.updated_by || '',
    name: requested.name,
    type: requested.type,
    namespaceId: requested.namespace_id,
  }
}
