import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import styled from 'styled-components'

export const StyledUl = styled('ul')`
  display: flex;
  list-style-type: none;
`

export const StyledLi = styled('li')`
  padding: 30px;
  border: 1px solid gray;
`
export const HeaderWrapper = styled(Box)`
  display: flex;
`
export const KeldaTypography = styled(Typography)`
  font-family: 'ubuntu';
`

export const StyledButton = styled(Button)`
  text-transform: capitalize !important;
`
