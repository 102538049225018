import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { AddEntityUserButton } from '../AddEntityUserButton'
import { RemoveEntityUserButton } from '../RemoveEntityUserButton'
import { AddRemoveEntityUserPayload } from '../../store/slices/models'

export const getRemoveRoleUsersColumnsConfig = (
  entityId: string,
  entityName: string,
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>,
  postActionHandler: () => void
) => {
  return [
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Action',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (row: any) => {
        return (
          <RemoveEntityUserButton
            userId={row.id}
            userEmail={row.email}
            entityId={entityId}
            entityName={entityName}
            entityAction={entityAction}
            postActionHandler={postActionHandler}
          />
        )
      },
    },
  ]
}

export const getAddRoleUsersColumnsConfig = (
  entityId: string,
  entityName: string,
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>,
  postActionHandler: () => void
) => {
  return [
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Action',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (row: any) => {
        return (
          <AddEntityUserButton
            userId={row.id}
            userEmail={row.email}
            entityId={entityId}
            entityName={entityName}
            entityAction={entityAction}
            postActionHandler={postActionHandler}
          />
        )
      },
    },
  ]
}
