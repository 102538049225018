import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR_NOTIFICATION_OPTIONS } from '../../configs/constants'
import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableHeaderWrapper,
  StyledTableTitle,
} from './elements'
import { Table } from '../Table'
import { PERMISSION_COLUMNS_CONFIG } from './tableConfig'
import { useEffect, useMemo } from 'react'
import { selectPermissions } from '../../store/slices/roles/selectors'
import { fetchPermissionsAction } from '../../store/slices/roles/actions'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { Modal } from '../Modal'
import { useModal } from '../../hooks/useModal'
import { PermissionCreateForm } from '../PermissionCreateForm'

const FETCH_ERROR_MESSAGE = 'An error occurred while fetching permissions!'

interface PermissionTableProps {
  roleId: string
}

const PermissionTable: React.FC<PermissionTableProps> = ({ roleId }) => {
  const ruleList = useSelector(selectPermissions(roleId))
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = useMemo(() => PERMISSION_COLUMNS_CONFIG, [])
  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  useEffect(() => {
    dispatch(
      fetchPermissionsAction({
        roleId,
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }, [])

  const postCreationHandler = () => {
    dispatch(
      fetchPermissionsAction({
        roleId: roleId,
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    closeModalHandler()
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <StyledTableTitle>Permission List</StyledTableTitle>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModalHandler}
        >
          Add Permission
        </Button>
        <Modal
          open={modalStatus}
          onCancel={closeModalHandler}
          title={'Add Permission'}
          content={
            <PermissionCreateForm
              roleId={roleId}
              postSubmitActionHandler={postCreationHandler}
            />
          }
        />
      </StyledTableHeaderWrapper>
      <StyledTableBodyWrapper>
        <Table columns={columns} data={ruleList} />
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default PermissionTable
