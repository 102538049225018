import { StyledCircularProgress, StyledLoaderWrapper } from './elements'

const Loader = (): JSX.Element => {
  return (
    <StyledLoaderWrapper>
      <StyledCircularProgress disableShrink />
    </StyledLoaderWrapper>
  )
}

export default Loader
