import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/** interface to define the GroupUser object */
export interface UserObj {
  id: string
  createdAt: number
  username: string
  enabled: boolean
  firstName: string
  lastName: string
  email: string
}

export interface Pagination {
  limit: number
  pageNumber: number
  total: number
  search: string
}

export interface currentGroupObj {
  groupId: string
  groupName: string
  groupUsers: UserObj[]
  nonGroupUsers: UserObj[]
  groupUsersPagination: Pagination
  nonGroupUsersPagination: Pagination
}

export interface GroupObj {
  groupId: string
  groupName: string
}

/** interface to declare GroupUser state */
type GroupUserState = currentGroupObj

/** the groupUser reducer name */
export const reducerName = 'currentGroup'

export const paginationInitialState: Pagination = {
  limit: 10,
  pageNumber: 1,
  search: '',
  total: 0,
}

/** initial reducer state */
export const initialGroupUserState: GroupUserState = {
  groupId: '',
  groupName: '',
  groupUsers: [],
  nonGroupUsers: [],
  groupUsersPagination: paginationInitialState,
  nonGroupUsersPagination: paginationInitialState,
}

/** slice */

const groupUserSlice = createSlice({
  name: reducerName,
  initialState: initialGroupUserState,
  reducers: {
    setGroup(state, action: PayloadAction<GroupObj>) {
      state.groupId = action.payload.groupId
      state.groupName = action.payload.groupName
    },
    setGroupUsers(state, action: PayloadAction<UserObj[]>) {
      state.groupUsers = [...action.payload]
    },
    setNonGroupUsers(state, action: PayloadAction<UserObj[]>) {
      state.nonGroupUsers = [...action.payload]
    },
    setGroupUsersPagination(state, action: PayloadAction<Pagination>) {
      state.groupUsersPagination = action.payload
    },
    setNonGroupUsersPagination(state, action: PayloadAction<Pagination>) {
      state.nonGroupUsersPagination = action.payload
    },
    resetCurrentGroup() {
      return initialGroupUserState
    },
  },
})

/** actions */

/** export the groupUser reducer actions */
export const {
  setGroup: setGroupAction,
  setGroupUsers: setGroupUsersAction,
  setNonGroupUsers: setNonGroupUsersAction,
  setGroupUsersPagination: setGroupUsersPaginationAction,
  setNonGroupUsersPagination: setNonGroupUsersPaginationAction,
  resetCurrentGroup: resetCurrentGroupAction,
} = groupUserSlice.actions

/** export the Group default reducer */
export default groupUserSlice.reducer
