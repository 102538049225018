import Delete from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { useConfirmModal } from '../../hooks/useConfirmModal'
import {
  fetchPermissionsAction,
  removePermissionAction,
} from '../../store/slices/roles/actions'
import { PermissionObj } from '../../store/slices/roles/slice'
import { ConfirmModal } from '../ConfirmModal'
import { StyledActionsWrapper } from './elements'

const FETCH_ERROR_MESSAGE = 'An error occurred while fetching permissions!'

interface PermissionTableActionsProps {
  permission: PermissionObj
}

const PermissionTableActions: React.FC<PermissionTableActionsProps> = ({
  permission,
}: PermissionTableActionsProps) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const postRemovalHandler = () => {
    dispatch(
      fetchPermissionsAction({
        roleId: permission.roleId,
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const { openConfirmationModal, getConfirmModalProps } = useConfirmModal({
    title: `Are you sure to remove this permission?`,
    description: `User will no longer have ${permission.allowedAction} access on ${permission.entityName}.`,
    keepModalOpenAfterOk: true,
    onOk: (closeModalHandler) => {
      dispatch(
        removePermissionAction({
          roleId: permission.roleId,
          permissionId: permission.id,
          postSuccessHandler: () => {
            enqueueSnackbar(
              'Permission removed successfully!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
            postRemovalHandler()
            closeModalHandler()
          },
          errorHandler: () => {
            enqueueSnackbar(
              'Failed to remove permission!',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  return (
    <StyledActionsWrapper>
      <IconButton size="small" onClick={() => openConfirmationModal()}>
        <Delete />
      </IconButton>
      <ConfirmModal {...getConfirmModalProps} />
    </StyledActionsWrapper>
  )
}

export default PermissionTableActions
