import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import createReducer from './rootReducers'
import rootSaga from './rootSagas'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { fetchInvitationsAction } from './slices/invitation/actions'

const sagaMiddleware = createSagaMiddleware()

/** configuration related redux persist */
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [], // add reducer to persist specific slices to localstorage
}

const rootStore = configureStore({
  reducer: persistReducer(persistConfig, createReducer()),
  /** setup redux toolkit to use saga instead of thunk */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          fetchInvitationsAction.type,
        ],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(rootStore)

export type Store = ReturnType<typeof rootStore.getState>
export type AppDispatch = typeof rootStore.dispatch

/** export the default root store  */
export default rootStore
