import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UserObj } from '../userlist/slice'

export const reducerName = 'activeUsers'

type UserlistState = UserObj[]

export const initialUserlistState: UserlistState = []

const activeUsersSlice = createSlice({
  name: reducerName,
  initialState: initialUserlistState,
  reducers: {
    setActiveUsers(state, action: PayloadAction<UserObj[]>) {
      return [...action.payload]
    },
    resetActiveUsers() {
      return []
    },
  },
})

export const {
  setActiveUsers: setActiveUsersAction,
  resetActiveUsers: resetActiveUsersAction,
} = activeUsersSlice.actions

export default activeUsersSlice.reducer
