import { createAction } from '@reduxjs/toolkit'
import { AddRemoveEntityUserPayload } from '../models'

const GROUP_USERS_PREFIX = 'GROUP_USERS'

export interface FetchGroupUserlistPayload {
  inclGroupId?: string
  errorHandler: () => void
}

export interface InitializeGroupUserStorePayload {
  groupId: string
  groupName: string
  errorHandler: () => void
}

export interface FetchNonGroupUserlistPayload {
  exclGroupId?: string
  errorHandler: () => void
}

export const initializeGroupUserStoreAction =
  createAction<InitializeGroupUserStorePayload>(
    `${GROUP_USERS_PREFIX}/API/INITIALIZE_GROUP_USER_STORE`
  )

export const fetchGroupUserlistAction = createAction<FetchGroupUserlistPayload>(
  `${GROUP_USERS_PREFIX}/API/FETCH_GROUP_USERLIST`
)

export const fetchNonGroupUserlistAction =
  createAction<FetchNonGroupUserlistPayload>(
    `${GROUP_USERS_PREFIX}/API/FETCH_NON_GROUP_USERLIST`
  )

export const addGroupUserAction = createAction<AddRemoveEntityUserPayload>(
  `${GROUP_USERS_PREFIX}/API/ADD_GROUP_USER`
)

export const removeGroupUserAction = createAction<AddRemoveEntityUserPayload>(
  `${GROUP_USERS_PREFIX}/API/REMOVE_GROUP_USER`
)
