import { SERVER_USERS_ENDPOINT } from '../../configs/endpoints'
import { axioService, GET, PUT } from '../../services/axios'
import {
  GetUserListPayload,
  GetUserlistResponse,
  ToggleUserActivationRequestPayload,
} from './models'

export const getUserlistFromServer = async (
  payload: GetUserListPayload
): Promise<GetUserlistResponse> => {
  const {
    offset,
    limit,
    search,
    enabled,
    inclGroupId,
    exclGroupId,
    exclRoleId,
  } = payload
  const response = await axioService(
    GET,
    SERVER_USERS_ENDPOINT,
    {
      offset,
      limit,
      q: search.trim() ? search : null,
      enabled,
      inclGroupId,
      exclGroupId,
      exclRoleId,
    },
    true
  )
  const { data, total_count, ...rest } = response.data
  return {
    ...rest,
    totalCount: total_count,
    data: data,
  }
}

export const toggleUserActivationInServer = async (
  userPayload: ToggleUserActivationRequestPayload
) => {
  const response = await axioService(
    PUT,
    SERVER_USERS_ENDPOINT + '/' + userPayload.userId,
    {
      enabled: userPayload.enabled,
      admin: userPayload.admin,
    },
    true
  )
  return response.status
}
