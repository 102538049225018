import { Box, Drawer } from '@mui/material'
import styled from 'styled-components'

const DRAWER_WIDTH = 240

export const StyledDrawer = styled(Drawer)`
  width: ${DRAWER_WIDTH}px;
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    box-sizing: 'border-box';
  }
`

export const StyledBox = styled(Box)`
  overflow: 'auto';
`
