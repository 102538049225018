import { Button, Tooltip } from '@mui/material'
import { KeldaTypography } from '../Header/elements'

interface GroupObjFromServer {
  id: string
  name: string
}

interface OrganizationListObj {
  groups: Array<GroupObjFromServer>
}

const UserOrganizationListCell = ({ groups }: OrganizationListObj) => {
  const groupCount = groups.length
  const firstGroup = groupCount === 0 ? '-' : groups[0].name

  if (groupCount <= 1) {
    return <>{firstGroup}</>
  }

  const otherGroups = (
    <>
      {groups.slice(1).map((group: GroupObjFromServer) => (
        <KeldaTypography color="inherit" key={group.id} variant="body2">
          {group.name}
        </KeldaTypography>
      ))}
    </>
  )

  const otherGroupsTooltip = (
    <Tooltip title={otherGroups} placement="right" arrow>
      <Button color="secondary">+{groupCount - 1} more</Button>
    </Tooltip>
  )

  return (
    <>
      {firstGroup} {otherGroupsTooltip}
    </>
  )
}

export default UserOrganizationListCell
