import * as React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { StyledTabs } from './elements'
import { Namespaces } from './Namespaces'
import { Roles } from './Roles'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const AccessControl = (): JSX.Element => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="Access Control"
        >
          <Tab label="Namespaces" />
          <Tab label="Access Groups" />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Namespaces />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Roles />
      </CustomTabPanel>
    </Box>
  )
}

export default AccessControl
