/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import keycloak from './keycloak'

/** Interface to describe a flexible object */
interface FlexObj {
  [key: string]: any
}

/** Request types */

/** GET REQUEST TYPE */
export const GET = 'GET'
type GET = typeof GET

/** POST REQUEST TYPE */
export const POST = 'POST'
type POST = typeof POST

/** PUT REQUEST TYPE */
export const PUT = 'PUT'
type PUT = typeof PUT

/** PATCH REQUEST TYPE */
export const PATCH = 'PATCH'
type PATCH = typeof PATCH

/** DELETE REQUEST TYPE */
export const DELETE = 'DELETE'
type DELETE = typeof DELETE

/**
 * axioGet makes a get request using the axio libary
 * @param {string} url - the url where the get request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioGet = async (
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${keycloak.token || ''}`,
        },
      }
    : { headers: { Accept: `application/json` } }
  const response = await axios.get(url, {
    ...config,
    params,
  })
  return await response
}

/**
 * axioPost makes a post request using the axio libary
 * @param {string} url - the url where the post request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioPost = async (
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${keycloak.token || ''}`,
        },
      }
    : { headers: { Accept: `application/json` } }
  const response = await axios.post(url, params, config)
  return await response
}

/**
 * axioPut makes a put request using the axio libary
 * @param {string} url - the url where the put request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioPut = async (
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${keycloak.token || ''}`,
        },
      }
    : { headers: { Accept: `application/json` } }
  const response = await axios.put(url, params, config)
  return await response
}

/**
 * axioPatch makes a patch request using the axio libary
 * @param {string} url - the url where the patch request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioPatch = async (
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${keycloak.token || ''}`,
        },
      }
    : { headers: { Accept: `application/json` } }
  const response = await axios.patch(url, params, config)
  return await response
}

/**
 * axioDelete makes a delete request using the axio libary
 * @param {string} url - the url where the delete request will be made
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioDelete = async (url: string, isProtected = false) => {
  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${keycloak.token || ''}`,
        },
      }
    : { headers: { Accept: `application/json` } }
  const response = await axios.delete(url, config)
  return await response
}

/**
 * axioService makes a server request using the axio libary
 * @param {GET|POST|DELETE} type - the type of request
 * @param {string} url - the url where the request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful; otherwise, error object
 */
export const axioService = async (
  type: GET | POST | PUT | DELETE | PATCH,
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  let response

  switch (type) {
    case POST:
      response = await axioPost(url, params, isProtected)
      break
    case PUT:
      response = await axioPut(url, params, isProtected)
      break
    case DELETE:
      response = await axioDelete(url, isProtected)
      break
    case GET:
      response = await axioGet(url, params, isProtected)
      break
    case PATCH:
      response = await axioPatch(url, params, isProtected)
      break
  }

  return await response
}
