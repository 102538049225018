import { createAction } from '@reduxjs/toolkit'

const USERLIST_PREFIX = 'USERLIST'

export interface FetchActiveUserlistPayload {
  offset: number
  limit: number
  search: string
  inclGroupId?: string
  exclGroupId?: string
  postSuccessHandler: (emailString: string) => void
  errorHandler: () => void
  enabled: boolean
}

/** actions */

export const fetchActiveUserlistAction =
  createAction<FetchActiveUserlistPayload>(
    `${USERLIST_PREFIX}/API/FETCH_ACTIVE_USERS`
  )
