import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../..'

interface ChangeInvitationStatusObj {
  invitationId: string
  status: string
}

/** interface to define the Invitation object */
export interface InvitationObj {
  id: string
  createdAt: string
  deleted: boolean
  groupId: string
  token: string
  expiryTime: number
  updatedAt: string
  createdBy: string
  updatedBy: string
  email: string
  groupName: string
  status: string
}

/** interface to declare invitation state */
type InvitationState = InvitationObj[]

/** the invitation reducer name */
export const reducerName = 'invitation'

/** initial reducer state */
export const initialInvitationState: InvitationState = []

/** slice */

const invitationSlice = createSlice({
  name: reducerName,
  initialState: initialInvitationState,
  reducers: {
    setInvitations(state, action: PayloadAction<InvitationObj[]>) {
      return [...action.payload]
    },
    addInvitation(state, action: PayloadAction<InvitationObj>) {
      return [...state, action.payload]
    },
    addInvitations(state, action: PayloadAction<InvitationObj[]>) {
      return [...state, ...action.payload]
    },
    changeInvitationStatus(
      state,
      action: PayloadAction<ChangeInvitationStatusObj>
    ) {
      return [
        ...state.map((iter: InvitationObj) =>
          iter.id === action.payload.invitationId
            ? { ...iter, status: action.payload.status }
            : iter
        ),
      ]
    },
    resetInvitation() {
      return initialInvitationState
    },
  },
})

/** actions */

/** export the invitation reducer actions */
export const {
  setInvitations: setInvitationsAction,
  addInvitation: addInvitationAction,
  addInvitations: addInvitationsAction,
  resetInvitation: resetInvitationAction,
  changeInvitationStatus: changeInvitationStatusAction,
} = invitationSlice.actions

/** selectors */

/** returns the invitations in state
 * @param {Store} state - the redux store
 * @return { InvitationObj[]} - the existing invitations
 */
export function selectInvitations(state: Store): InvitationObj[] {
  return state[reducerName]
}

/** export the invitation default reducer */
export default invitationSlice.reducer
