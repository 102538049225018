import { Dialog, DialogTitle } from '@mui/material'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  & .muidialog-paper: {
    width: 80%;
    max-height: 435px;
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 400px;
`
export const StyledTitle = styled.div`
  color: rgb(255, 255, 255);
`
