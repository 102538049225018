import { StyledContainer, StyledCard } from './elements'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { getServerInvitationByToken } from '../../api/invitations/requests'
import { Loader } from '../../components/Loader'
import {
  FormValues as AcceptInvitationFormValues,
  InvitationAcceptForm,
} from '../../components/InvitationAcceptForm'
import { NotFound } from '../NotFound'

/** intital form value */
const INITIAL_FORM_VALUES: AcceptInvitationFormValues = {
  email: '',
  password: '',
  groupId: '',
  token: '',
  expiryTime: 0,
  groupName: '',
  firstName: '',
  lastName: '',
  confirmPassword: '',
}

const Invite = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const { token, expiry } = useParams()
  const [formValues, setFormValues] =
    useState<AcceptInvitationFormValues>(INITIAL_FORM_VALUES)

  const [notFound, setNotFound] = useState<boolean>(false)

  useEffect(() => {
    if (token && expiry) {
      const fetchInvitation = async () => {
        setLoading(true)
        try {
          const response = await getServerInvitationByToken(token, expiry)
          setFormValues({
            email: response.email,
            password: '',
            groupId: response.groupId,
            token: response.token,
            expiryTime: response.expiryTime,
            groupName: response.groupName,
            firstName: '',
            lastName: '',
            confirmPassword: '',
          })
        } catch (exception) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((exception as any)?.response.status === 404) {
            setNotFound(true)
          }
        }
        setLoading(false)
      }
      fetchInvitation()
    }
  }, [token, expiry])

  return loading ? (
    <Loader />
  ) : notFound ? (
    <NotFound description={'The invitation does not exists.'} />
  ) : (
    <StyledContainer>
      <StyledCard>
        <InvitationAcceptForm formValues={formValues} />
      </StyledCard>
    </StyledContainer>
  )
}

export default Invite
