import { Outlet } from 'react-router-dom'
import Header from '../../components/Header/Header'
import { Sidebar } from '../../components/Sidebar'
import { StyledBox } from './elements'

const AppLayout = () => {
  return (
    <>
      <Header />
      <StyledBox>
        <Sidebar />
        <Outlet />
      </StyledBox>
    </>
  )
}

export default AppLayout
