import { createAction } from '@reduxjs/toolkit'
import { CreateInvitationRequestPayload } from '../../../api/invitations/models'

const INVITATION_PREFIX = 'INVITATION'

export interface CreateInvitationPayload
  extends CreateInvitationRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface CancelInvitationPayload {
  invitationId: string
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface FetchInvitationsPayload {
  offset: number
  limit: number
  search: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

/** actions */

export const fetchInvitationsAction = createAction<FetchInvitationsPayload>(
  `${INVITATION_PREFIX}/API/FETCH_INVITATIONS`
)

export const createInvitationAction = createAction<CreateInvitationPayload>(
  `${INVITATION_PREFIX}/API/CREATE_INVITATION`
)

export const cancelInvitationAction = createAction<CancelInvitationPayload>(
  `${INVITATION_PREFIX}/API/CANCEL_INVITATION`
)
