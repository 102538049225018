import moment from 'moment'
import { NamespaceObj } from '../../../store/slices/namespace/slice'

export const NAMESPACE_COLUMNS_CONFIG = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Source ID',
    accessor: 'sourceId',
  },
  {
    Header: 'Created At',
    accessor: ({ createdAt }: NamespaceObj) =>
      moment(createdAt).format('DD.MM.YYYY, HH:mm'),
  },
]
