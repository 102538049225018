import { useEffect, useMemo, useState } from 'react'
import { selectNamespaces } from '../../../store/slices/namespace/slice'
import { useDispatch, useSelector } from 'react-redux'
import { NAMESPACE_COLUMNS_CONFIG } from './tableConfig'
import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
} from './elements'
import {
  Button,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
} from '@mui/material'
import { Add, SearchOutlined, Sync } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { fetchNamespacesAction } from '../../../store/slices/namespace/actions'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../../configs/constants'
import NamespaceCreateForm from '../../../components/NamespaceCreateForm/NamespaceCreateForm'
import { Modal } from '../../../components/Modal'
import { Table } from '../../../components/Table'
import { useModal } from '../../../hooks/useModal'
import { syncNamespacesInServer } from '../../../api/namespaces/requests'
import { useConfirmModal } from '../../../hooks/useConfirmModal'
import { ConfirmModal } from '../../../components/ConfirmModal'

const ROWS_LIMIT = 20
const FETCH_ERROR_MESSAGE = 'An error occurred while fetching namespaces!'

const Namespaces = (): JSX.Element => {
  const namespaceList = useSelector(selectNamespaces)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = useMemo(() => NAMESPACE_COLUMNS_CONFIG, [])
  const [search, setSearch] = useState<string>('')
  const [total, setTotal] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  useEffect(() => {
    dispatch(
      fetchNamespacesAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
  }, [])

  const postCreationHander = () => {
    dispatch(
      fetchNamespacesAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
    closeModalHandler()
  }

  const pageChangeHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchNamespacesAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setPageNumber(value)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
  }

  const searchChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.target.value)
    dispatch(
      fetchNamespacesAction({
        offset: 0,
        limit: ROWS_LIMIT,
        search: event.target.value,
        postSuccessHandler: (total) => {
          setPageNumber(1)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
  }

  const { openConfirmationModal, getConfirmModalProps } = useConfirmModal({
    title: `Are you sure to sync namespaces?`,
    description: `This will also remove obsolute permissions. This action cannot be undone.`,
    keepModalOpenAfterOk: true,
    onOk: (closeModalHandler) => {
      syncNamespacesInServer()
        .then(() => {
          dispatch(
            fetchNamespacesAction({
              offset: (pageNumber - 1) * ROWS_LIMIT,
              limit: ROWS_LIMIT,
              search: search,
              postSuccessHandler: (total) => {
                enqueueSnackbar(
                  'Namespaces synced successfully',
                  SUCCESS_NOTIFICATION_OPTIONS
                )
                setTotal(total)
                closeModalHandler()
              },
              errorHandler: () => {
                enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
                console.error(FETCH_ERROR_MESSAGE)
              },
            })
          )
        })
        .catch(() =>
          enqueueSnackbar(
            'Failed to sync namespaces!',
            ERROR_NOTIFICATION_OPTIONS
          )
        )
    },
  })

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <TextField
          placeholder="Search"
          sx={{ mr: 1 }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandler}
        />
        <Stack direction="row" spacing={1}>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Add />}
            onClick={openModalHandler}
          >
            Create Namespace
          </Button>
          <Modal
            open={modalStatus}
            onCancel={closeModalHandler}
            title={'New Namespace'}
            content={
              <NamespaceCreateForm
                postSubmitActionHandler={postCreationHander}
              />
            }
          />
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Sync />}
            onClick={openConfirmationModal}
          >
            Sync Namespaces
          </Button>
          <ConfirmModal {...getConfirmModalProps} />
        </Stack>
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table columns={columns} data={namespaceList} />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(total / ROWS_LIMIT)}
            color="secondary"
            page={pageNumber}
            onChange={pageChangeHandler}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default Namespaces
