import { createAction } from '@reduxjs/toolkit'
import {
  CreatePermissionRequestPayload,
  CreateRoleRequestPayload,
  RemovePermissionRequestPayload,
} from '../../../api/roles/models'
import { AddRemoveEntityUserPayload } from '../models'

const ROLE_PREFIX = 'ROLE'

export interface CreateRolePayload extends CreateRoleRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface FetchRolesPayload {
  offset: number
  limit: number
  search: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

export interface FetchPermissionsPayload {
  roleId: string
  errorHandler: () => void
}

export interface FetchRoleUsersPayload {
  roleId: string
  offset: number
  limit: number
  search: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

export interface CreatePermissionPayload
  extends CreatePermissionRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface RemovePermissionPayload
  extends RemovePermissionRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

/** actions */

export const fetchRolesAction = createAction<FetchRolesPayload>(
  `${ROLE_PREFIX}/API/FETCH_ROLES`
)

export const createRoleAction = createAction<CreateRolePayload>(
  `${ROLE_PREFIX}/API/CREATE_ROLE`
)

export const fetchPermissionsAction = createAction<FetchPermissionsPayload>(
  `${ROLE_PREFIX}/API/FETCH_PERMISSIONS`
)

export const createPermissionAction = createAction<CreatePermissionPayload>(
  `${ROLE_PREFIX}/API/CREATE_PERMISSION`
)

export const removePermissionAction = createAction<RemovePermissionPayload>(
  `${ROLE_PREFIX}/API/REMOVE_PERMISSION`
)

export const fetchRoleUsersAction = createAction<FetchRoleUsersPayload>(
  `${ROLE_PREFIX}/API/FETCH_ROLE_USERS`
)

export const addRoleUsersAction = createAction<AddRemoveEntityUserPayload>(
  `${ROLE_PREFIX}/API/ADD_ROLE_USERS`
)

export const removeRoleUsersAction = createAction<AddRemoveEntityUserPayload>(
  `${ROLE_PREFIX}/API/REMOVE_ROLE_USERS`
)
