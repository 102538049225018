import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getEntitiesFromServer,
  createEntityInServer,
  removeEntityInServer,
} from '../../../api/entities/requests'
import {
  CreateEntityPayload,
  FetchEntitiesPayload,
  RemoveEntityPayload,
  createEntityAction,
  fetchEntitiesAction,
  removeEntityAction,
} from './actions'
import { addEntityAction, setEntitiesAction } from './slice'

function* fetchEntitiesAsync(action: {
  payload: FetchEntitiesPayload
}): SagaIterator {
  const {
    postSuccessHandler,
    errorHandler,
    offset,
    limit,
    search,
    namespaceId,
  } = action.payload
  try {
    const response = yield call(() =>
      getEntitiesFromServer(offset, limit, search, namespaceId)
    )
    yield put(setEntitiesAction(response.data))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* createEntityAsync(action: {
  payload: CreateEntityPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => createEntityInServer(rest))
    yield put(addEntityAction(response))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

function* removeEntityAsync(action: {
  payload: RemoveEntityPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => removeEntityInServer(rest))
    console.log(response.status)
    if (response.status === 204) {
      yield call(postSuccessHandler)
    } else {
      yield call(errorHandler)
    }
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchEntitiesSaga(): SagaIterator {
  yield takeLatest(fetchEntitiesAction, fetchEntitiesAsync)
  yield takeLatest(createEntityAction, createEntityAsync)
  yield takeLatest(removeEntityAction, removeEntityAsync)
}
