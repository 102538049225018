import { PasswordUpdateForm } from '../../components/PasswordUpdateForm'
import { StyledCard, StyledContainer } from './elements'

const Password = () => {
  return (
    <StyledContainer>
      <StyledCard>
        <PasswordUpdateForm />
      </StyledCard>
    </StyledContainer>
  )
}

export default Password
