import { ToggleSwitch } from '../../components/ToggleSwitch'
import { UserOrganizationListCell } from '../../components/UserOrganizationListCell'
import {
  SHOULD_INCLUDE_ADMIN_COLUMN,
  SHOULD_INCLUDE_ORGANIZATION_COLUMN,
} from '../../configs/constants'

export const USERLIST_COLUMNS_CONFIG = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  SHOULD_INCLUDE_ORGANIZATION_COLUMN && {
    Header: 'Organization (s)',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <UserOrganizationListCell groups={row.groups} />
    },
  },
  {
    Header: 'Enabled',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <ToggleSwitch user={row} payload={{ enabled: !row.enabled }} />
    },
  },
  SHOULD_INCLUDE_ADMIN_COLUMN && {
    Header: 'Admin',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <ToggleSwitch user={row} payload={{ admin: !row.admin }} />
    },
  },
].filter((v) => v)
