import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getProfileFromServer,
  updateServerProfile,
} from '../../../api/profile/requests'
import {
  fetchProfileAction,
  updateProfileAction,
  UpdateProfilePayload,
} from './actions'
import { setProfileAction } from './slice'

function* fetchProfileAsync(): SagaIterator {
  try {
    const response = yield call(getProfileFromServer)
    yield put(setProfileAction(response))
  } catch (exception) {
    console.log(exception)
  }
}

function* updateProfileAsync(action: {
  payload: UpdateProfilePayload
}): SagaIterator {
  const { profile, postSuccessHandler, errorHandler } = action.payload
  try {
    yield call(() => updateServerProfile(profile))
    yield put(setProfileAction(profile))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.log(exception)
  }
}

export function* profileSaga(): SagaIterator {
  yield takeLatest(fetchProfileAction, fetchProfileAsync)
  yield takeLatest(updateProfileAction, updateProfileAsync)
}
