import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../..'

/** interface to define the Entity object */
export interface EntityObj {
  id: string
  createdAt: string
  deleted: boolean
  updatedAt: string
  createdBy: string
  updatedBy: string
  name: string
  type: string
  namespaceId: string
}

/** interface to declare entity state */
type EntityState = EntityObj[]

/** the entity reducer name */
export const reducerName = 'entity'

/** initial reducer state */
export const initialEntityState: EntityState = []

/** slice */

const entitySlice = createSlice({
  name: reducerName,
  initialState: initialEntityState,
  reducers: {
    setEntities(state, action: PayloadAction<EntityObj[]>) {
      return action.payload
    },
    addEntity(state, action: PayloadAction<EntityObj>) {
      return [...state, action.payload]
    },
    resetEntity() {
      return initialEntityState
    },
  },
})

/** actions */

/** export the entity reducer actions */
export const {
  setEntities: setEntitiesAction,
  addEntity: addEntityAction,
  resetEntity: resetEntityAction,
} = entitySlice.actions

/** selectors */

/** returns the entities in state
 * @param {Store} state - the redux store
 * @return { EntityObj[]} - the existing entities
 */
export function selectEntities(state: Store): EntityObj[] {
  return state[reducerName]
}

/** export the entity default reducer */
export default entitySlice.reducer
