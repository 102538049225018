import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR_NOTIFICATION_OPTIONS } from '../../configs/constants'
import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
  StyledTableTitle,
} from './elements'
import { Table } from '../Table'
import {
  getAddRoleUsersColumnsConfig,
  getRemoveRoleUsersColumnsConfig,
} from './tableConfig'
import { useEffect, useMemo, useState } from 'react'
import { selectRoleUsers } from '../../store/slices/roles/selectors'
import {
  addRoleUsersAction,
  fetchRoleUsersAction,
  removeRoleUsersAction,
} from '../../store/slices/roles/actions'
import { InputAdornment, Pagination, TextField } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { fetchUserlistAction } from '../../store/slices/userlist/actions'
import { selectUserlist } from '../../store/slices/userlist/selectors'
import debounce from 'lodash.debounce'

const ROWS_LIMIT = 20
const FETCH_ERROR_MESSAGE = 'An error occurred while fetching users!'

interface RoleUsersTableProps {
  roleId: string
  roleName: string
}

const RoleUsersTable: React.FC<RoleUsersTableProps> = ({
  roleId,
  roleName,
}) => {
  const roleUserList = useSelector(selectRoleUsers(roleId))
  const nonRoleUserList = useSelector(selectUserlist)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [searchRoleUser, setSearchRoleUser] = useState<string>('')
  const [searchNonRoleUser, setSearchNonRoleUser] = useState<string>('')
  const [totalRoleUser, setTotalRoleUser] = useState<number>(0)
  const [totalNonRoleUser, setTotalNonRoleUser] = useState<number>(0)
  const [pageNumberRoleUser, setPageNumberRoleUser] = useState<number>(1)
  const [pageNumberNonRoleUser, setPageNumberNonRoleUser] = useState<number>(1)

  useEffect(() => {
    initHandler()
  }, [])

  const initHandler = () => {
    dispatch(
      fetchRoleUsersAction({
        offset: (pageNumberRoleUser - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: searchRoleUser,
        roleId,
        postSuccessHandler: (total) => {
          setTotalRoleUser(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    dispatch(
      fetchUserlistAction({
        offset: (pageNumberNonRoleUser - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: searchNonRoleUser,
        exclRoleId: roleId,
        postSuccessHandler: (total) => {
          setTotalNonRoleUser(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const pageChangeHandlerRoleUser = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchRoleUsersAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: searchRoleUser,
        roleId,
        postSuccessHandler: (total) => {
          setPageNumberRoleUser(value)
          setTotalRoleUser(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const pageChangeHandlerNonRoleUser = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchUserlistAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: searchNonRoleUser,
        exclRoleId: roleId,
        postSuccessHandler: (total) => {
          setPageNumberNonRoleUser(value)
          setTotalNonRoleUser(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const debouncedSearchChangeHandlerRoleUser = useMemo(
    () =>
      debounce((query: string) => {
        setSearchRoleUser(query)
        dispatch(
          fetchRoleUsersAction({
            offset: 0,
            limit: ROWS_LIMIT,
            search: query,
            roleId,
            postSuccessHandler: (total) => {
              setPageNumberRoleUser(1)
              setTotalRoleUser(total)
            },
            errorHandler: () => {
              enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
            },
          })
        )
      }, 500),
    [dispatch, enqueueSnackbar, roleId]
  )

  const searchChangeHandlerRoleUser = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSearchChangeHandlerRoleUser(event.target.value)
  }

  const debouncedSearchChangeHandlerNonRoleUser = useMemo(
    () =>
      debounce((query: string) => {
        setSearchNonRoleUser(query)
        dispatch(
          fetchUserlistAction({
            offset: 0,
            limit: ROWS_LIMIT,
            search: query,
            exclRoleId: roleId,
            postSuccessHandler: (total) => {
              setPageNumberNonRoleUser(1)
              setTotalNonRoleUser(total)
            },
            errorHandler: () => {
              enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
            },
          })
        )
      }, 500),
    [dispatch, enqueueSnackbar, roleId]
  )

  const searchChangeHandlerNonRoleUser = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSearchChangeHandlerNonRoleUser(event.target.value)
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <StyledTableTitle>Existing Users</StyledTableTitle>
        <TextField
          placeholder="Search"
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandlerRoleUser}
        />
      </StyledTableHeaderWrapper>
      <StyledTableBodyWrapper>
        <Table
          columns={getRemoveRoleUsersColumnsConfig(
            roleId,
            roleName,
            removeRoleUsersAction,
            initHandler
          )}
          data={roleUserList}
          maxHeight={350}
          stickyHeader={true}
        />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(totalRoleUser / ROWS_LIMIT)}
            color="secondary"
            page={pageNumberRoleUser}
            onChange={pageChangeHandlerRoleUser}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>

      <StyledTableHeaderWrapper>
        <StyledTableTitle>Add User</StyledTableTitle>
        <TextField
          placeholder="Search"
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandlerNonRoleUser}
        />
      </StyledTableHeaderWrapper>
      <StyledTableBodyWrapper>
        <Table
          columns={getAddRoleUsersColumnsConfig(
            roleId,
            roleName,
            addRoleUsersAction,
            initHandler
          )}
          data={nonRoleUserList}
          maxHeight={350}
          stickyHeader={true}
        />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(totalNonRoleUser / ROWS_LIMIT)}
            color="secondary"
            page={pageNumberNonRoleUser}
            onChange={pageChangeHandlerNonRoleUser}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default RoleUsersTable
