import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { App } from './App'
import { Loader } from './components/Loader'
import { KEYCLOAK_INIT_OPTIONS } from './configs/constants'
import './index.css'
import reportWebVitals from './reportWebVitals'
import keycloak from './services/keycloak'
import store, { persistor } from './store'
import { SnackbarUtilsConfigurator } from './components/SnackbarConfigurator'

const container = document.getElementById('root') as HTMLElement

const root = ReactDOMClient.createRoot(container)

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={KEYCLOAK_INIT_OPTIONS}
    LoadingComponent={<Loader />}
  >
    <SnackbarProvider maxSnack={3}>
      <SnackbarUtilsConfigurator />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  </ReactKeycloakProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
