import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledInviteHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
`

export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 10px;
  align-items: center;
`

export const StyledForm = styled.form`
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledTextInput = styled(TextField)`
  width: 350px;
`

export const StyledImg = styled.img`
  width: 100px;
  margin: 0 0 25px 0;
`

export const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 10px;
`

export const StyledSubtitle = styled(Typography)`
  margin-top: 7px !important;
  color: rgb(255, 255, 255, 0.5);
`
