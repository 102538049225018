import {
  RoleObj,
  PermissionObj,
  RoleUserObj,
} from '../../store/slices/roles/slice'
import { ServerRoleObj, ServerPermissionObj, ServerRoleUserObj } from './models'

export const mapRoleFromServer = (requested: ServerRoleObj): RoleObj => {
  return {
    id: requested.id,
    createdAt: requested.created_at,
    deleted: requested.deleted,
    updatedAt: requested.updated_at || '',
    createdBy: requested.created_by || '',
    updatedBy: requested.updated_by || '',
    name: requested.name,
    description: requested.description,
  }
}

export const mapPermissionFromServer = (
  requested: ServerPermissionObj
): PermissionObj => {
  return {
    id: requested.id,
    roleId: requested.role_id,
    entityId: requested.entity_id,
    allowedAction: requested.allowed_action,
    entityName: requested.entity_name,
    namespaceName: requested.namespace_name,
  }
}

export const mapRoleUserFromServer = (
  requested: ServerRoleUserObj
): RoleUserObj => {
  return {
    id: requested.id,
    createdAt: requested.created_at,
    username: requested.username,
    firstName: requested.first_name,
    lastName: requested.last_name,
    email: requested.email,
    enabled: requested.enabled,
  }
}
