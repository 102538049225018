import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const StyledLoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2c2e39;
`

export const StyledCircularProgress = styled(CircularProgress)`
  color: rgb(118, 168, 146) !important;
`
