import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../..'

/** interface to define the Namespace object */
export interface NamespaceObj {
  id: string
  createdAt: string
  deleted: boolean
  updatedAt: string
  createdBy: string
  updatedBy: string
  name: string
  description: string
  sourceId: string
}

/** interface to declare namespace state */
type NamespaceState = NamespaceObj[]

/** the namespace reducer name */
export const reducerName = 'namespace'

/** initial reducer state */
export const initialNamespaceState: NamespaceState = []

/** slice */

const namespaceSlice = createSlice({
  name: reducerName,
  initialState: initialNamespaceState,
  reducers: {
    setNamespaces(state, action: PayloadAction<NamespaceObj[]>) {
      return action.payload
    },
    addNamespace(state, action: PayloadAction<NamespaceObj>) {
      return [...state, action.payload]
    },
    resetNamespace() {
      return initialNamespaceState
    },
  },
})

/** actions */

/** export the namespace reducer actions */
export const {
  setNamespaces: setNamespacesAction,
  addNamespace: addNamespaceAction,
  resetNamespace: resetNamespaceAction,
} = namespaceSlice.actions

/** selectors */

/** returns the namespaces in state
 * @param {Store} state - the redux store
 * @return { NamespaceObj[]} - the existing namespaces
 */
export function selectNamespaces(state: Store): NamespaceObj[] {
  return state[reducerName]
}

/** export the namespace default reducer */
export default namespaceSlice.reducer
