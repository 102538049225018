import { ProfileObj } from '../../store/slices/profile/slice'
import { ServerProfileObj } from './models'

export const mapProfileFromServer = (
  requested: ServerProfileObj
): ProfileObj => {
  return {
    firstName: requested.first_name || '',
    lastName: requested.last_name || '',
    email: requested.email || '',
    groupName: requested.group_name || '',
    groupId: requested.group_id || '',
  }
}
