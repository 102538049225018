import { useState } from 'react'

interface UseModalProps {
  status: boolean
}

interface UseModal {
  modalStatus: boolean
  openModalHandler: () => void
  closeModalHandler: () => void
}

const DEFAULT_MODAL_PROPS = {
  status: false,
}

const useModal = ({
  status,
}: UseModalProps = DEFAULT_MODAL_PROPS): UseModal => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(status)

  const openModalHandler = () => setIsModalOpen(true)

  const closeModalHandler = () => setIsModalOpen(false)

  return { modalStatus: isModalOpen, openModalHandler, closeModalHandler }
}

export default useModal
