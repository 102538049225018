import { createAction } from '@reduxjs/toolkit'
import { CreateGroupRequestPayload } from '../../../api/groups/models'

const GROUP_PREFIX = 'GROUP'

export interface FetchGroupsPayload {
  offset?: number
  limit?: number
  search?: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

export interface CreateGroupPayload extends CreateGroupRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export const fetchGroupsAction = createAction<FetchGroupsPayload>(
  `${GROUP_PREFIX}/API/FETCH_GROUPS`
)

export const createGroupAction = createAction<CreateGroupPayload>(
  `${GROUP_PREFIX}/API/CREATE_GROUP`
)
