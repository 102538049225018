import { SERVER_GROUP_ENDPOINT } from '../../configs/endpoints'
import { axioService, GET, POST } from '../../services/axios'
import { GroupObj } from '../../store/slices/group/slice'
import { mapGroupFromServer } from './mappings'
import {
  CreateGroupRequestPayload,
  GetGroupsResponse,
  ServerGetGroupsResponse,
  ServerGroupObj,
} from './models'

export const getGroupsFromServer = async (
  offset = 0,
  limit = 20,
  search = ''
): Promise<GetGroupsResponse> => {
  const response = await axioService(
    GET,
    SERVER_GROUP_ENDPOINT,
    search.trim() ? { offset, limit, q: search } : { offset, limit },
    true
  )
  const { data, total_count, ...rest } =
    response.data as ServerGetGroupsResponse
  return {
    ...rest,
    totalCount: total_count,
    data: data.map(
      (iter: ServerGroupObj): GroupObj => mapGroupFromServer(iter)
    ),
  }
}

export const createGroupInServer = async (
  createPayload: CreateGroupRequestPayload
): Promise<GroupObj> => {
  const response = await axioService(
    POST,
    SERVER_GROUP_ENDPOINT,
    {
      name: createPayload.name,
      description: createPayload.description,
    },
    true
  )
  return mapGroupFromServer(response.data)
}
