import { SERVER_NAMESPACE_ENDPOINT } from '../../configs/endpoints'
import { axioService, GET, POST } from '../../services/axios'
import { NamespaceObj } from '../../store/slices/namespace/slice'
import { mapNamespaceFromServer } from './mappings'
import {
  CreateNamespaceRequestPayload,
  GetNamespacesResponse,
  ServerGetNamespacesResponse,
  ServerNamespaceObj,
} from './models'

export const getNamespacesFromServer = async (
  offset = 0,
  limit = 20,
  search = ''
): Promise<GetNamespacesResponse> => {
  const response = await axioService(
    GET,
    SERVER_NAMESPACE_ENDPOINT,
    search.trim() ? { offset, limit, q: search } : { offset, limit },
    true
  )
  const { data, total_count, ...rest } =
    response.data as ServerGetNamespacesResponse
  return {
    ...rest,
    totalCount: total_count,
    data: data.map(
      (iter: ServerNamespaceObj): NamespaceObj => mapNamespaceFromServer(iter)
    ),
  }
}

export const createNamespaceInServer = async (
  createPayload: CreateNamespaceRequestPayload
): Promise<NamespaceObj> => {
  const response = await axioService(
    POST,
    SERVER_NAMESPACE_ENDPOINT,
    {
      name: createPayload.name,
      description: createPayload.description,
      source_id: createPayload.sourceId,
    },
    true
  )
  return mapNamespaceFromServer(response.data)
}

export const syncNamespacesInServer = async (): Promise<void> => {
  await axioService(POST, SERVER_NAMESPACE_ENDPOINT + '/sync', {}, true)
}
