import { ArrowBack } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { FC } from 'react'
import {
  StyledBody,
  StyledButtonWrapper,
  StyledCard,
  StyledContainer,
  StyledImg,
  StyledInviteHeaderWrapper,
  StyledSubtitle,
} from './elements'

interface NotFoundProps {
  title?: string
  description?: string
}

const NotFound: FC<NotFoundProps> = ({
  title,
  description,
}: NotFoundProps): JSX.Element => {
  const { keycloak } = useKeycloak()

  const onClickHandler = () =>
    (window.location.href = keycloak.createLoginUrl())

  return (
    <StyledContainer>
      <StyledCard>
        <StyledInviteHeaderWrapper>
          <StyledImg src={`/kelda-logo-white.svg`} alt="" />
          <Typography variant="h4" noWrap={true}>
            Kelda Portal
          </Typography>
          <StyledSubtitle variant="subtitle1" noWrap={true}>
            {title || 'Not Found'}
          </StyledSubtitle>
          <StyledBody variant="body1" noWrap={true}>
            {description || 'The page does not exists.'}
          </StyledBody>
        </StyledInviteHeaderWrapper>
        <StyledButtonWrapper>
          <Button
            color="secondary"
            variant="text"
            type="submit"
            startIcon={<ArrowBack />}
            onClick={onClickHandler}
          >
            Redirect to Login
          </Button>
        </StyledButtonWrapper>
      </StyledCard>
    </StyledContainer>
  )
}

export default NotFound
