import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createGroupInServer,
  getGroupsFromServer,
} from '../../../api/groups/requests'
import {
  createGroupAction,
  CreateGroupPayload,
  fetchGroupsAction,
  FetchGroupsPayload,
} from './actions'
import { addGroupAction, setGroupsAction } from './slice'

function* fetchGroupsAsync(action: {
  payload: FetchGroupsPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search } =
    action.payload
  try {
    const response = yield call(() =>
      getGroupsFromServer(offset, limit, search)
    )
    yield put(setGroupsAction(response.data))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* createGroupAsync(action: {
  payload: CreateGroupPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    const response = yield call(() => createGroupInServer(rest))
    yield put(addGroupAction(response))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchGroupsSaga(): SagaIterator {
  yield takeLatest(fetchGroupsAction, fetchGroupsAsync)
  yield takeLatest(createGroupAction, createGroupAsync)
}
