import { InvitationObj } from '../../store/slices/invitation/slice'
import {
  AcceptInvitationObj,
  ServerAcceptInvitationObj,
  ServerInvitationObj,
} from './models'

export const mapInvitationFromServer = (
  requested: ServerInvitationObj
): InvitationObj => {
  return {
    id: requested.id,
    createdAt: requested.created_at,
    deleted: requested.deleted,
    groupId: requested.group_id,
    token: requested.token,
    expiryTime: requested.expiry_time,
    updatedAt: requested.updated_at || '',
    createdBy: requested.created_by || '',
    updatedBy: requested.updated_by || '',
    email: requested.email,
    groupName: requested.group_name,
    status: requested.status,
  }
}

export const mapAcceptInvitationToServer = (
  requested: AcceptInvitationObj
): ServerAcceptInvitationObj => {
  return {
    email: requested.email,
    group_id: requested.groupId,
    group_name: requested.groupName,
    token: requested.token,
    expiry_time: requested.expiryTime,
    first_name: requested.firstName,
    last_name: requested.lastName,
    password: requested.password,
  }
}
