import Keycloak from 'keycloak-js'
import {
  KEYCLOAK_ENDPOINT,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
} from '../configs/constants'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: KEYCLOAK_ENDPOINT,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
})

export default keycloak
