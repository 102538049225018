import { createAction } from '@reduxjs/toolkit'
import {
  CreateEntityRequestPayload,
  RemoveEntityRequestPayload,
} from '../../../api/entities/models'

const ENTITY_PREFIX = 'ENTITY'

export interface CreateEntityPayload extends CreateEntityRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface RemoveEntityPayload extends RemoveEntityRequestPayload {
  postSuccessHandler: () => void
  errorHandler: () => void
}

export interface FetchEntitiesPayload {
  offset: number
  limit: number
  search: string
  namespaceId: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

/** actions */

export const fetchEntitiesAction = createAction<FetchEntitiesPayload>(
  `${ENTITY_PREFIX}/API/FETCH_ENTITIES`
)

export const createEntityAction = createAction<CreateEntityPayload>(
  `${ENTITY_PREFIX}/API/CREATE_ENTITY`
)

export const removeEntityAction = createAction<RemoveEntityPayload>(
  `${ENTITY_PREFIX}/API/REMOVE_ENTITY`
)
