import { combineReducers, Reducer } from '@reduxjs/toolkit'
import invitationReducer, {
  reducerName as invitation,
} from './slices/invitation/slice'
import groupReducer, { reducerName as group } from './slices/group/slice'
import groupUserReducer, {
  reducerName as groupUser,
} from './slices/groupUser/slice'
import profileReducer, { reducerName as profile } from './slices/profile/slice'
import userlistReducer, {
  reducerName as userlist,
} from './slices/userlist/slice'
import activeUsersReducer, {
  reducerName as activeUsers,
} from './slices/activeUsers/slice'
import namespaceReducer, {
  reducerName as namespace,
} from './slices/namespace/slice'
import entityReducer, { reducerName as entity } from './slices/entity/slice'
import roleReducer, { reducerName as role } from './slices/roles/slice'

/** interface to describe default reducers */
interface DefaultReducers {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

/** reducers */

/** Initial reducers in the reducer registry */
const defaultReducers: DefaultReducers = {}
/** add invitations reducer to registry */
defaultReducers[invitation] = invitationReducer
/** add groups reducer to registry */
defaultReducers[group] = groupReducer
/** add groupUser reducer to registry */
defaultReducers[groupUser] = groupUserReducer
/** add profile reducer to registry */
defaultReducers[profile] = profileReducer
/** add userlist reducer to registry */
defaultReducers[userlist] = userlistReducer
/** add activeUserEmails reducer to registry */
defaultReducers[activeUsers] = activeUsersReducer
/** add namespace reducer to registry */
defaultReducers[namespace] = namespaceReducer
/** add entity reducer to registry */
defaultReducers[entity] = entityReducer
/** add role reducer to registry */
defaultReducers[role] = roleReducer

/** get reducers */

/** create method to combine reducers */
const createReducer = (): Reducer => {
  return combineReducers({
    ...defaultReducers,
  })
}

export default createReducer
