import debounce from 'lodash.debounce'

import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
  StyledTableTitle,
} from './elements'
import { useEffect, useMemo, useState } from 'react'
import { Table } from '../../components/Table'
import { ORGANIZATION_COLUMNS_CONFIG } from './tableConfig'
import { selectGroups } from '../../store/slices/group/slice'
import { fetchGroupsAction } from '../../store/slices/group/actions'
import { Button, InputAdornment, Pagination, TextField } from '@mui/material'
import { Add, SearchOutlined } from '@mui/icons-material'
import { Modal } from '../../components/Modal'
import { useSnackbar } from 'notistack'
import { ERROR_NOTIFICATION_OPTIONS } from '../../configs/constants'
import { useModal } from '../../hooks/useModal'
import { GroupCreateForm } from '../../components/GroupCreateForm'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const ROWS_LIMIT = 20
const INITIAL_PAGE_NUMBER = 1
const INITIAL_SEARCH = ''
const FETCH_ERROR_MESSAGE = 'An error occurred while fetching organizations!'

const Organizations = (): JSX.Element => {
  const groupList = useAppSelector(selectGroups)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [search, setSearch] = useState<string>(INITIAL_SEARCH)
  const [total, setTotal] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(INITIAL_PAGE_NUMBER)

  useEffect(() => {
    dispatch(
      fetchGroupsAction({
        offset: (INITIAL_PAGE_NUMBER - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: INITIAL_SEARCH,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }, [dispatch, enqueueSnackbar])

  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  const postCreationHander = () => {
    dispatch(
      fetchGroupsAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    closeModalHandler()
  }

  const pageChangeHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchGroupsAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setPageNumber(value)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const debouncedSearchChangeHandler = useMemo(
    () =>
      debounce((query: string) => {
        setSearch(query)
        dispatch(
          fetchGroupsAction({
            offset: 0,
            limit: ROWS_LIMIT,
            search: query,
            postSuccessHandler: (total) => {
              setPageNumber(1)
              setTotal(total)
            },
            errorHandler: () => {
              enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
            },
          })
        )
      }, 500),
    [dispatch, enqueueSnackbar]
  )

  const searchChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSearchChangeHandler(event.target.value)
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <StyledTableTitle>Organizations</StyledTableTitle>
        <TextField
          placeholder="Search"
          sx={{ mr: 1 }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandler}
        />
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModalHandler}
        >
          Add Organization
        </Button>
        <Modal
          open={modalStatus}
          onCancel={closeModalHandler}
          title={'Add Organization'}
          content={
            <GroupCreateForm postSubmitActionHandler={postCreationHander} />
          }
        />
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table columns={ORGANIZATION_COLUMNS_CONFIG} data={groupList} />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(total / ROWS_LIMIT)}
            color="secondary"
            page={pageNumber}
            onChange={pageChangeHandler}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default Organizations
