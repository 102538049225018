import { SERVER_INVITATION_ENDPOINT } from '../../configs/endpoints'
import { axioService, GET, PATCH, POST } from '../../services/axios'
import { InvitationObj } from '../../store/slices/invitation/slice'
import {
  mapAcceptInvitationToServer,
  mapInvitationFromServer,
} from './mappings'
import {
  AcceptInvitationObj,
  CreateInvitationRequestPayload,
  GetInvitationsResponse,
  ServerGetInvitationsResponse,
  ServerInvitationObj,
} from './models'

export const getInvitationsFromServer = async (
  offset = 0,
  limit = 20,
  search = ''
): Promise<GetInvitationsResponse> => {
  const response = await axioService(
    GET,
    SERVER_INVITATION_ENDPOINT,
    search.trim() ? { offset, limit, q: search } : { offset, limit },
    true
  )
  const { data, total_count, ...rest } =
    response.data as ServerGetInvitationsResponse
  return {
    ...rest,
    totalCount: total_count,
    data: data.map(
      (iter: ServerInvitationObj): InvitationObj =>
        mapInvitationFromServer(iter)
    ),
  }
}

export const createInvitationInServer = async (
  createPayload: CreateInvitationRequestPayload
): Promise<InvitationObj> => {
  const response = await axioService(
    POST,
    SERVER_INVITATION_ENDPOINT,
    {
      email: createPayload.email,
      group_id: createPayload.groupId,
      group_name: createPayload.groupName,
    },
    true
  )
  return mapInvitationFromServer(response.data)
}

export const cancelInvitationInServer = async (
  invitationId: string
): Promise<InvitationObj> => {
  const response = await axioService(
    PATCH,
    `${SERVER_INVITATION_ENDPOINT}/${invitationId}/cancel`,
    {},
    true
  )
  return mapInvitationFromServer(response.data)
}

export const getServerInvitationByToken = async (
  token: string,
  expiry: string
): Promise<InvitationObj> => {
  const response = await axioService(
    GET,
    `${SERVER_INVITATION_ENDPOINT}/${token}/${expiry}`,
    {},
    true
  )
  return mapInvitationFromServer(response.data)
}

export const acceptServerInvitation = async (
  acceptInvitationPayload: AcceptInvitationObj
): Promise<InvitationObj> => {
  const response = await axioService(
    POST,
    `${SERVER_INVITATION_ENDPOINT}/accept`,
    mapAcceptInvitationToServer(acceptInvitationPayload),
    true
  )
  return mapInvitationFromServer(response.data)
}
