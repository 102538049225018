import { GroupObj } from '../../store/slices/group/slice'
import { ServerGroupObj } from './models'

export const mapGroupFromServer = (requested: ServerGroupObj): GroupObj => {
  return {
    id: requested.id,
    createdAt: requested.created_at,
    deleted: requested.deleted,
    updatedAt: requested.updated_at || '',
    createdBy: requested.created_by || '',
    updatedBy: requested.updated_by || '',
    name: requested.name,
    description: requested.description,
    userCount: requested.user_count,
  }
}
