import { createAction } from '@reduxjs/toolkit'

const USERLIST_PREFIX = 'USERLIST'

export interface FetchUserlistPayload {
  offset: number
  limit: number
  search: string
  inclGroupId?: string
  exclGroupId?: string
  exclRoleId?: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

export interface ToggleUserPropertiesPayload {
  userId: string
  enabled: boolean
  admin: boolean
  postSuccessHandler: () => void
  errorHandler: () => void
}

/** actions */

export const fetchUserlistAction = createAction<FetchUserlistPayload>(
  `${USERLIST_PREFIX}/API/FETCH_USERLIST`
)

export const toggleUserPropertiesAction =
  createAction<ToggleUserPropertiesPayload>(
    `${USERLIST_PREFIX}/API/TOGGLE_USER_PROPERTIES`
  )
