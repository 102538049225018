import { Close } from '@mui/icons-material'
import { Breakpoint, DialogContent, Divider, IconButton } from '@mui/material'
import { ReactNode } from 'react'
import { StyledDialog, StyledDialogTitle, StyledTitle } from './elements'

interface ModalProps {
  title: string
  open: boolean
  onCancel: () => void
  content: ReactNode
  maxWidth?: false | Breakpoint | undefined
  fullWidth?: boolean
}

const Modal: React.FC<ModalProps> = ({
  title,
  open,
  content,
  onCancel,
  maxWidth = 'sm',
  fullWidth = false,
}: ModalProps) => (
  <StyledDialog maxWidth={maxWidth} open={open} fullWidth={fullWidth}>
    <StyledDialogTitle>
      <StyledTitle>{title}</StyledTitle>
      <IconButton onClick={onCancel} size="large">
        <Close></Close>
      </IconButton>
    </StyledDialogTitle>
    <Divider />
    <DialogContent>{content}</DialogContent>
  </StyledDialog>
)

export default Modal
