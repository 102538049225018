import { SERVER_ROLE_ENDPOINT } from '../../configs/endpoints'
import { axioService, DELETE, GET, POST } from '../../services/axios'
import {
  RoleObj,
  PermissionObj,
  RoleUserObj,
} from '../../store/slices/roles/slice'
import {
  mapRoleFromServer,
  mapPermissionFromServer,
  mapRoleUserFromServer,
} from './mappings'
import {
  CreateRoleRequestPayload,
  GetRolesResponse,
  ServerGetRolesResponse,
  ServerRoleObj,
  ServerPermissionObj,
  CreatePermissionRequestPayload,
  RemovePermissionRequestPayload,
  GetRoleUsersResponse,
  ServerGetRoleUsersResponse,
  ServerRoleUserObj,
  ServerAddRemoveRoleUserPayload,
  AddRemoveRoleUserRequestPayload,
} from './models'

export const getRolesFromServer = async (
  offset = 0,
  limit = 20,
  search = ''
): Promise<GetRolesResponse> => {
  const response = await axioService(
    GET,
    SERVER_ROLE_ENDPOINT,
    search.trim() ? { offset, limit, q: search } : { offset, limit },
    true
  )
  const { data, total_count, ...rest } = response.data as ServerGetRolesResponse
  return {
    ...rest,
    totalCount: total_count,
    data: data.map((iter: ServerRoleObj): RoleObj => mapRoleFromServer(iter)),
  }
}

export const createRoleInServer = async (
  createPayload: CreateRoleRequestPayload
): Promise<RoleObj> => {
  const response = await axioService(
    POST,
    SERVER_ROLE_ENDPOINT,
    {
      name: createPayload.name,
      description: createPayload.description,
    },
    true
  )
  return mapRoleFromServer(response.data)
}

export const createPermissionInServer = async (
  createPayload: CreatePermissionRequestPayload
): Promise<PermissionObj> => {
  const SERVER_PERMISSION_ENDPOINT = `${SERVER_ROLE_ENDPOINT}/${createPayload.roleId}/rules`
  const response = await axioService(
    POST,
    SERVER_PERMISSION_ENDPOINT,
    {
      namespace_id: createPayload.namespaceId,
      allowed_action: createPayload.allowedAction,
    },
    true
  )
  return mapPermissionFromServer(response.data)
}

export const removePermissionInServer = async (
  payload: RemovePermissionRequestPayload
) => {
  const SERVER_PERMISSION_ENDPOINT = `${SERVER_ROLE_ENDPOINT}/${payload.roleId}/rules`
  const response = await axioService(
    DELETE,
    `${SERVER_PERMISSION_ENDPOINT}/${payload.permissionId}`,
    {},
    true
  )
  return response
}

export const getPermissionsFromServer = async (
  roleId: string
): Promise<PermissionObj[]> => {
  const SERVER_PERMISSION_ENDPOINT = `${SERVER_ROLE_ENDPOINT}/${roleId}/rules`
  const response = await axioService(GET, SERVER_PERMISSION_ENDPOINT, {}, true)
  const data = response.data as ServerPermissionObj[]
  return data.map(
    (iter: ServerPermissionObj): PermissionObj => mapPermissionFromServer(iter)
  )
}

export const getRoleUsersFromServer = async (
  offset = 0,
  limit = 20,
  search = '',
  roleId = ''
): Promise<GetRoleUsersResponse> => {
  const response = await axioService(
    GET,
    `${SERVER_ROLE_ENDPOINT}/${roleId}/users`,
    search.trim() ? { offset, limit, q: search } : { offset, limit },
    true
  )
  const { data, total_count, ...rest } =
    response.data as ServerGetRoleUsersResponse
  return {
    ...rest,
    totalCount: total_count,
    data: data.map(
      (iter: ServerRoleUserObj): RoleUserObj => mapRoleUserFromServer(iter)
    ),
  }
}

export const addRoleUserInServer = async (
  payload: AddRemoveRoleUserRequestPayload
) => {
  const requestPayload: ServerAddRemoveRoleUserPayload = {
    add_users: [payload.userId],
    remove_users: [],
  }
  const response = await axioService(
    POST,
    `${SERVER_ROLE_ENDPOINT}/${payload.entityId}/users`,
    requestPayload,
    true
  )
  return response
}

export const removeRoleUserInServer = async (
  payload: AddRemoveRoleUserRequestPayload
) => {
  const requestPayload: ServerAddRemoveRoleUserPayload = {
    add_users: [],
    remove_users: [payload.userId],
  }
  const response = await axioService(
    POST,
    `${SERVER_ROLE_ENDPOINT}/${payload.entityId}/users`,
    requestPayload,
    true
  )
  return response
}
