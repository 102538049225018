import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/** interface to define the Role object */
export interface PermissionObj {
  id: string
  roleId: string
  entityId: string
  allowedAction: string
  entityName: string
  namespaceName: string
}

export interface RoleUserObj {
  id: string
  createdAt: number
  username: string
  enabled: boolean
  firstName: string
  lastName: string
  email: string
}

export interface RoleObj {
  id: string
  createdAt: string
  deleted: boolean
  updatedAt: string
  createdBy: string
  updatedBy: string
  name: string
  description: string
  permissions?: PermissionObj[]
  users?: RoleUserObj[]
}

/** interface to declare role state */
type RoleState = RoleObj[]

/** the role reducer name */
export const reducerName = 'role'

/** initial reducer state */
export const initialRoleState: RoleState = []

/** slice */

const roleSlice = createSlice({
  name: reducerName,
  initialState: initialRoleState,
  reducers: {
    setRoles(state, action: PayloadAction<RoleObj[]>) {
      return [...action.payload]
    },
    setPermissions(
      state,
      {
        payload: { roleId, permissions },
      }: PayloadAction<{ roleId: string; permissions: PermissionObj[] }>
    ) {
      const roleObj = state.find((item) => item.id === roleId)
      if (roleObj) roleObj.permissions = permissions
    },
    setRoleUsers(
      state,
      {
        payload: { roleId, users },
      }: PayloadAction<{ roleId: string; users: RoleUserObj[] }>
    ) {
      const roleObj = state.find((item) => item.id === roleId)
      if (roleObj) roleObj.users = users
    },
    addRole(state, action: PayloadAction<RoleObj>) {
      return [...state, action.payload]
    },
    addPermission(
      state,
      {
        payload: { roleId, permission },
      }: PayloadAction<{ roleId: string; permission: PermissionObj }>
    ) {
      const roleObj = state.find((item) => item.id === roleId)
      if (roleObj) roleObj.permissions?.push(permission)
    },
    resetRole() {
      return initialRoleState
    },
  },
})

/** actions */

/** export the role reducer actions */
export const {
  setRoles: setRolesAction,
  addRole: addRoleAction,
  addPermission: addPermissionAction,
  resetRole: resetRoleAction,
  setPermissions: setPermissionsAction,
  setRoleUsers: setRoleUsersAction,
} = roleSlice.actions

/** export the role default reducer */
export default roleSlice.reducer
