/** backend server endpoint */
export const SERVER_ENDPOINT =
  process.env.REACT_APP_SERVER_URI || 'http://localhost:8000'
/** server product endpoint */
export const SERVER_PRODUCT_ENDPOINT = `${SERVER_ENDPOINT}/products`
/** server invitation endpoint */
export const SERVER_INVITATION_ENDPOINT = `${SERVER_ENDPOINT}/invitations`
/** server userlist endpoint */
export const SERVER_USERS_ENDPOINT = `${SERVER_ENDPOINT}/users`
/** server profile endpoint */
export const SERVER_PROFILE_ENDPOINT = `${SERVER_ENDPOINT}/users/me`
/** server groups endpoint */
export const SERVER_GROUP_ENDPOINT = `${SERVER_ENDPOINT}/groups`
/** server update password endpoint */
export const SERVER_UPDATE_PASSWORD_ENDPOINT = `${SERVER_ENDPOINT}/users/me/reset-password`
/** server namespace endpoint */
export const SERVER_NAMESPACE_ENDPOINT = `${SERVER_ENDPOINT}/access-control/namespaces`
/** server entity endpoint */
export const SERVER_ENTITY_ENDPOINT = `${SERVER_ENDPOINT}/access-control/entities`
/** server roles endpoint */
export const SERVER_ROLE_ENDPOINT = `${SERVER_ENDPOINT}/roles`
