import Delete from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { useConfirmModal } from '../../hooks/useConfirmModal'
import { cancelInvitationAction } from '../../store/slices/invitation/actions'
import { InvitationObj } from '../../store/slices/invitation/slice'
import { ConfirmModal } from '../ConfirmModal'
import { StyledActionsWrapper } from './elements'
import { useAppDispatch } from '../../store/hooks'

interface InvitationTableActionsProps {
  invitation: InvitationObj
}

const InvitationTableActions: React.FC<InvitationTableActionsProps> = ({
  invitation,
}: InvitationTableActionsProps) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { openConfirmationModal, getConfirmModalProps } = useConfirmModal({
    title: `Cancel invitation?`,
    description: `Invitation link sent to ${invitation.email} will no longer be valid.`,
    keepModalOpenAfterOk: true,
    onOk: (closeModalHandler) => {
      dispatch(
        cancelInvitationAction({
          invitationId: invitation.id,
          postSuccessHandler: () => {
            enqueueSnackbar(
              'Invitation cancelled successfully !!!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
            closeModalHandler()
          },
          errorHandler: () => {
            enqueueSnackbar(
              'Invitation cancelled failed !!!',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  return (
    <StyledActionsWrapper>
      <IconButton
        /** TODO: remove the status magical string and handle properly */
        disabled={invitation.status !== 'pending'}
        size="small"
        onClick={() => openConfirmationModal()}
      >
        <Delete />
      </IconButton>
      <ConfirmModal {...getConfirmModalProps} />
    </StyledActionsWrapper>
  )
}

export default InvitationTableActions
