import { createTheme } from '@mui/material/styles'

export const keldaBlackTheme = createTheme({
  palette: {
    background: {
      default: '#2c2e39',
      paper: '#191b25',
    },
    text: {
      primary: '#ffffff',
      secondary: '#fff',
    },
    primary: {
      main: '#ffffff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#A9F0D1',
      contrastText: '#191b25',
    },
    mode: 'dark',
  },
})

// button color : background: #A9F0D1;
// Contrast Color : #191b25
