import React from 'react'
import { Button } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

import {
  StyledAlert,
  StyledButtonWrapper,
  StyledForm,
  StyledTextField,
} from './elements'
import { CreateNamespaceRequestPayload } from '../../api/namespaces/models'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { createNamespaceAction } from '../../store/slices/namespace/actions'

interface NamespaceCreateFormProps {
  postSubmitActionHandler: () => void
}

type FormValues = CreateNamespaceRequestPayload

/** intital form value */
const INITIAL_FORM_VALUE: FormValues = {
  name: '',
  description: '',
  sourceId: '',
}

const NamespaceCreateForm: React.FC<NamespaceCreateFormProps> = ({
  postSubmitActionHandler,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUE,
    onSubmit: (values: FormValues) => {
      dispatch(
        createNamespaceAction({
          ...values,
          postSuccessHandler: () => {
            enqueueSnackbar(
              'Namespace created successfully!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
            postSubmitActionHandler()
          },
          errorHandler: () => {
            enqueueSnackbar(
              'Failed to create namespace!',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  return (
    <div>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledAlert severity="warning">
          Don&apos;t create a namespace manually if you&apos;re unsure. Use the{' '}
          <strong>Sync Namespaces</strong> button instead.
        </StyledAlert>
        <StyledTextField
          id="name"
          name="name"
          label="Name"
          margin="normal"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <StyledTextField
          id="description"
          name="description"
          label="Description"
          margin="normal"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
        <StyledTextField
          id="sourceId"
          name="sourceId"
          label="Source ID"
          margin="normal"
          value={formik.values.sourceId}
          onChange={formik.handleChange}
          error={formik.touched.sourceId && Boolean(formik.errors.sourceId)}
          helperText={formik.touched.sourceId && formik.errors.sourceId}
        />
        <StyledButtonWrapper>
          <Button color="secondary" variant="contained" type="submit">
            Create Namespace
          </Button>
        </StyledButtonWrapper>
      </StyledForm>
    </div>
  )
}

export default NamespaceCreateForm
