import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  addGroupUserInServer,
  removeGroupUserInServer,
} from '../../../api/groupUsers/requests'
import { getUserlistFromServer } from '../../../api/userlist/requests'
import { Snackbar } from '../../../components/SnackbarConfigurator'
import {
  addGroupUserAction,
  fetchGroupUserlistAction,
  FetchGroupUserlistPayload,
  fetchNonGroupUserlistAction,
  FetchNonGroupUserlistPayload,
  initializeGroupUserStoreAction,
  InitializeGroupUserStorePayload,
  removeGroupUserAction,
} from '../groupUser/actions'
import {
  selectGroupUsersPagination,
  selectNonGroupUsersPagination,
} from './selectors'
import {
  setGroupAction,
  setGroupUsersAction,
  setGroupUsersPaginationAction,
  setNonGroupUsersAction,
  setNonGroupUsersPaginationAction,
} from './slice'
import { AddRemoveEntityUserPayload } from '../models'

function* fetchGroupUserlistAsync(action: {
  payload: FetchGroupUserlistPayload
}): SagaIterator {
  const { inclGroupId, errorHandler } = action.payload
  const { limit, pageNumber, search } = yield select(selectGroupUsersPagination)
  const offset = (pageNumber - 1) * limit
  try {
    const response = yield call(() =>
      getUserlistFromServer({ offset, limit, search, inclGroupId })
    )
    yield put(setGroupUsersAction(response.data))
    yield put(
      setGroupUsersPaginationAction({
        total: response.totalCount,
        limit,
        pageNumber,
        search,
      })
    )
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* fetchNonGroupUserlistAsync(action: {
  payload: FetchNonGroupUserlistPayload
}): SagaIterator {
  const { exclGroupId, errorHandler } = action.payload
  const { limit, pageNumber, search } = yield select(
    selectNonGroupUsersPagination
  )
  const offset = (pageNumber - 1) * limit
  try {
    const response = yield call(() =>
      getUserlistFromServer({ offset, limit, search, exclGroupId })
    )
    yield put(setNonGroupUsersAction(response.data))
    yield put(
      setNonGroupUsersPaginationAction({
        total: response.totalCount,
        limit,
        pageNumber,
        search,
      })
    )
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* addGroupUserAsync(action: {
  payload: AddRemoveEntityUserPayload
}): SagaIterator {
  const {
    postSuccessHandler,
    errorHandler,
    userId,
    entityId: groupId,
  } = action.payload
  try {
    yield call(() => addGroupUserInServer({ userId, entityId: groupId }))
    yield call(postSuccessHandler)
    yield call(fetchGroupUserlistAsync, {
      payload: {
        inclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error('An error occurred while fetching organization users!')
        },
      },
    })
    yield call(fetchNonGroupUserlistAsync, {
      payload: {
        exclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error(
            'An error occurred while fetching non organization users!'
          )
        },
      },
    })
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

function* removeGroupUserAsync(action: {
  payload: AddRemoveEntityUserPayload
}): SagaIterator {
  const {
    postSuccessHandler,
    errorHandler,
    userId,
    entityId: groupId,
  } = action.payload
  try {
    yield call(() => removeGroupUserInServer({ userId, entityId: groupId }))
    yield call(postSuccessHandler)
    yield call(fetchGroupUserlistAsync, {
      payload: {
        inclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error('An error occurred while fetching organization users!')
        },
      },
    })
    yield call(fetchNonGroupUserlistAsync, {
      payload: {
        exclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error(
            'An error occurred while fetching non organization users!'
          )
        },
      },
    })
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

function* initializeGroupUserStoreAsync(action: {
  payload: InitializeGroupUserStorePayload
}): SagaIterator {
  const { errorHandler, groupId, groupName } = action.payload
  try {
    yield put(setGroupAction({ groupId, groupName }))
    yield call(fetchGroupUserlistAsync, {
      payload: {
        inclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error('An error occurred while fetching organization users!')
        },
      },
    })
    yield call(fetchNonGroupUserlistAsync, {
      payload: {
        exclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error(
            'An error occurred while fetching non organization users!'
          )
        },
      },
    })
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchGroupUsersSaga(): SagaIterator {
  yield takeLatest(
    initializeGroupUserStoreAction,
    initializeGroupUserStoreAsync
  )
  yield takeLatest(fetchGroupUserlistAction, fetchGroupUserlistAsync)
  yield takeLatest(fetchNonGroupUserlistAction, fetchNonGroupUserlistAsync)
  yield takeLatest(addGroupUserAction, addGroupUserAsync)
  yield takeLatest(removeGroupUserAction, removeGroupUserAsync)
}
