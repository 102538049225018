import { NamespaceObj } from '../../store/slices/namespace/slice'
import { ServerNamespaceObj } from './models'

export const mapNamespaceFromServer = (
  requested: ServerNamespaceObj
): NamespaceObj => {
  return {
    id: requested.id,
    createdAt: requested.created_at,
    deleted: requested.deleted,
    updatedAt: requested.updated_at || '',
    createdBy: requested.created_by || '',
    updatedBy: requested.updated_by || '',
    name: requested.name,
    description: requested.description,
    sourceId: requested.source_id,
  }
}
