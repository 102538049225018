import moment from 'moment'
import { RoleObj } from '../../../store/slices/roles/slice'
import { RoleTableActions } from '../../../components/RoleTableActions'

export const ROLE_COLUMNS_CONFIG = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Created At',
    accessor: ({ createdAt }: RoleObj) =>
      moment(createdAt).format('DD.MM.YYYY, HH:mm'),
  },
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: { original: RoleObj } }) => (
      <RoleTableActions role={row.original} />
    ),
  },
]
