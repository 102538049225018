import { useKeycloak } from '@react-keycloak/web'
import { Navigate, Outlet } from 'react-router-dom'
import { KEYCLOAK_KP_ADMIN_ROLE } from '../../configs/constants'
import { LOCAL_PROFILE_ROUTE } from '../../configs/routes'

const AdminLayout = () => {
  const { keycloak } = useKeycloak()
  return keycloak.hasRealmRole(KEYCLOAK_KP_ADMIN_ROLE) ? (
    <Outlet />
  ) : (
    <Navigate to={LOCAL_PROFILE_ROUTE} />
  )
}

export default AdminLayout
