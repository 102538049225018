import { ProfileUpdateForm } from '../../components/ProfileUpdateForm'
import { StyledCard, StyledContainer } from './elements'

const Profile = () => {
  return (
    <StyledContainer>
      <StyledCard>
        <ProfileUpdateForm />
      </StyledCard>
    </StyledContainer>
  )
}

export default Profile
