import { PermissionObj } from '../../store/slices/roles/slice'
import { Chip } from '@mui/material'
import { PermissionTableActions } from '../PermissionTableActions'

export const PERMISSION_COLUMNS_CONFIG = [
  {
    Header: 'Namespace',
    accessor: 'namespaceName',
  },
  {
    Header: 'Entity',
    accessor: 'entityName',
  },
  {
    Header: 'Allowed Action',
    accessor: ({ allowedAction }: PermissionObj) =>
      allowedAction === 'read' ? (
        <Chip label="Read" color="secondary" />
      ) : (
        <Chip label="Write" color="success" />
      ),
  },
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: { original: PermissionObj } }) => (
      <PermissionTableActions permission={row.original} />
    ),
  },
]
