import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getUserlistFromServer } from '../../../api/userlist/requests'
import { setActiveUsersAction } from './slice'
import {
  FetchActiveUserlistPayload,
  fetchActiveUserlistAction,
} from './actions'
import { selectActiveUserEmailString } from '../userlist/selectors'

function* fetchActiveUserlistAsync(action: {
  payload: FetchActiveUserlistPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search, enabled } =
    action.payload
  try {
    const response = yield call(() =>
      getUserlistFromServer({ offset, limit, search, enabled })
    )
    yield put(setActiveUsersAction(response.data))
    const activeUserEmailString = yield select(selectActiveUserEmailString)
    yield call(() => postSuccessHandler(activeUserEmailString))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

export function* fetchActiveUsersSaga(): SagaIterator {
  yield takeLatest(fetchActiveUserlistAction, fetchActiveUserlistAsync)
}
