import { Store } from '../..'
import { GroupObj, Pagination, reducerName, UserObj } from './slice'
/** returns the current Group in state
 * @param {Store} state - the redux store
 * @return { GroupObj } - the current Group
 */
export function selectCurrentGroup(state: Store): GroupObj {
  return {
    groupId: state[reducerName].groupId,
    groupName: state[reducerName].groupName,
  }
}

/** returns the GroupUsers in state
 * @param {Store} state - the redux store
 * @return { UserObj[]} - the existing GroupUsers
 */
export function selectGroupUsers(state: Store): UserObj[] {
  return state[reducerName].groupUsers
}

/** returns the NonGroupUsers in state
 * @param {Store} state - the redux store
 * @return { UserObj[]} - the nonGroupUsers
 */
export function selectNonGroupUsers(state: Store): UserObj[] {
  return state[reducerName].nonGroupUsers
}

/** returns the GroupUsersPagination in state
 * @param {Store} state - the redux store
 * @return { Pagination} - the groupUsersPagination
 */
export function selectGroupUsersPagination(state: Store): Pagination {
  return state[reducerName].groupUsersPagination
}

/** returns the NonGroupUsersPagination in state
 * @param {Store} state - the redux store
 * @return { Pagination} - the nonGroupUsersPagination
 */
export function selectNonGroupUsersPagination(state: Store): Pagination {
  return state[reducerName].nonGroupUsersPagination
}
