import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../..'

/** interface to define the Group object */
export interface GroupObj {
  id: string
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
  deleted: boolean
  name: string
  description: string
  userCount: number
}

/** interface to declare Group state */
type GroupState = GroupObj[]

/** the group reducer name */
export const reducerName = 'group'

/** initial reducer state */
export const initialGroupState: GroupState = []

/** slice */

const groupSlice = createSlice({
  name: reducerName,
  initialState: initialGroupState,
  reducers: {
    setGroups(state, action: PayloadAction<GroupObj[]>) {
      return [...action.payload]
    },
    addGroup(state, action: PayloadAction<GroupObj>) {
      return [...state, action.payload]
    },
    addGroups(state, action: PayloadAction<GroupObj[]>) {
      return [...state, ...action.payload]
    },
    resetGroup() {
      return initialGroupState
    },
  },
})

/** actions */

/** export the group reducer actions */
export const {
  setGroups: setGroupsAction,
  addGroup: addGroupAction,
  addGroup: addGroupsAction,
  resetGroup: resetGroupAction,
} = groupSlice.actions

/** selectors */

/** returns the Groups in state
 * @param {Store} state - the redux store
 * @return { GroupObj[]} - the existing Groups
 */
export function selectGroups(state: Store): GroupObj[] {
  return state[reducerName]
}

/** export the Group default reducer */
export default groupSlice.reducer
