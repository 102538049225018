import { GroupManageButton } from '../../components/GroupManageButton'

export const ORGANIZATION_COLUMNS_CONFIG = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'User Count',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return row.userCount === 0 ? '-' : row.userCount
    },
  },
  {
    Header: 'Action',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <GroupManageButton groupId={row.id} groupName={row.name} />
    },
  },
]
