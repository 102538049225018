import { OptionsObject, useSnackbar, ProviderContext } from 'notistack'
import {
  ERROR_NOTIFICATION_OPTIONS,
  INFO_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
  WARNING_NOTIFICATION_OPTIONS,
} from '../../configs/constants'

// TODO: Create a middleware and replace this global variable approach of notification
let useSnackbarRef: ProviderContext
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export default {
  success(msg: string) {
    this.toast(msg, SUCCESS_NOTIFICATION_OPTIONS)
  },
  warning(msg: string) {
    this.toast(msg, WARNING_NOTIFICATION_OPTIONS)
  },
  info(msg: string) {
    this.toast(msg, INFO_NOTIFICATION_OPTIONS)
  },
  error(msg: string) {
    this.toast(msg, ERROR_NOTIFICATION_OPTIONS)
  },
  toast(msg: string, options: OptionsObject) {
    useSnackbarRef.enqueueSnackbar(msg, options)
  },
}
