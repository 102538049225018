import { Chip } from '@mui/material'
import moment from 'moment'
import { InvitationTableActions } from '../../components/InvitationTableActions'
import { InvitationObj } from '../../store/slices/invitation/slice'
import { SHOULD_INCLUDE_ORGANIZATIONS } from '../../configs/constants'

const organizationColumns = [
  {
    Header: 'Organization',
    accessor: 'groupName',
  },
  {
    Header: 'Organization ID',
    accessor: 'groupId',
  },
]

const invitationRequiredColumns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Created At',
    accessor: ({ createdAt }: InvitationObj) =>
      moment(createdAt).format('DD.MM.YYYY, HH:mm'),
  },
  {
    Header: 'Expiry',
    accessor: ({ expiryTime }: InvitationObj) =>
      moment.unix(expiryTime).fromNow(),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }: { value: string }) => {
      const getColor = () => {
        switch (value) {
          case 'cancelled':
            return 'error'
          case 'accepted':
            return 'success'
          case 'pending':
            return 'info'
          case 'expired':
            return 'warning'
        }
      }
      return (
        <Chip
          size="small"
          label={value}
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.6rem',
          }}
          color={getColor()}
        />
      )
    },
  },
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: { original: InvitationObj } }) => (
      <InvitationTableActions invitation={row.original} />
    ),
  },
]

export const INVITATION_COLUMNS_CONFIG = invitationRequiredColumns
  .slice(0, 2)
  .concat(SHOULD_INCLUDE_ORGANIZATIONS ? organizationColumns : [])
  .concat(invitationRequiredColumns.slice(2))
