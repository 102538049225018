import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { StyledDialogTitle } from './elements'

export interface ConfirmModalProps {
  open: boolean
  title: string
  description?: string
  hideCancel?: boolean
  okText?: string
  cancelText?: string
  onOk: () => void
  onCancel: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  title,
  description,
  hideCancel,
  okText,
  cancelText,
  onOk,
  onCancel,
}: ConfirmModalProps) => (
  <Dialog open={open}>
    <StyledDialogTitle>{title}</StyledDialogTitle>
    {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      {!hideCancel && (
        <Button onClick={onCancel}>{cancelText || 'Disagree'}</Button>
      )}
      <Button color="secondary" onClick={onOk}>
        {okText || 'Agree'}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmModal
