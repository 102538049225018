import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../..'
import { RoleObj } from './slice'

export const selectRoles = (state: Store) => state.role

export const selectPermissions = (roleId: string) =>
  createSelector(
    selectRoles,
    (role) =>
      role.find((item: RoleObj) => item.id === roleId)?.permissions || []
  )

export const selectRoleUsers = (roleId: string) =>
  createSelector(
    selectRoles,
    (role) => role.find((item: RoleObj) => item.id === roleId)?.users || []
  )
