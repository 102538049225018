import { createSelector } from '@reduxjs/toolkit'
import { UserObj } from './slice'
import { Store } from '../..'

export const selectUserlist = (state: Store): UserObj[] => state.userlist

export const selectActiveUsers = (state: Store): UserObj[] => state.activeUsers

export const selectActiveUserEmailString = createSelector(
  selectActiveUsers,
  (users) => {
    return users
      .filter((user: UserObj) => user.enabled && user.email)
      .map((user: UserObj) => user.email)
      .join(',')
  }
)
