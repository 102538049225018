import { SERVER_ENTITY_ENDPOINT } from '../../configs/endpoints'
import { axioService, DELETE, GET, POST } from '../../services/axios'
import { EntityObj } from '../../store/slices/entity/slice'
import { mapEntityFromServer } from './mappings'
import {
  CreateEntityRequestPayload,
  GetEntitiesResponse,
  ServerGetEntitiesResponse,
  ServerEntityObj,
  RemoveEntityRequestPayload,
} from './models'

export const getEntitiesFromServer = async (
  offset = 0,
  limit = 20,
  search = '',
  namespaceId = ''
): Promise<GetEntitiesResponse> => {
  const response = await axioService(
    GET,
    SERVER_ENTITY_ENDPOINT,
    {
      offset,
      limit,
      q: search === '' ? null : search,
      namespace_id: namespaceId === '' ? null : namespaceId,
    },
    true
  )
  const { data, total_count, ...rest } =
    response.data as ServerGetEntitiesResponse
  return {
    ...rest,
    totalCount: total_count,
    data: data.map(
      (iter: ServerEntityObj): EntityObj => mapEntityFromServer(iter)
    ),
  }
}

export const createEntityInServer = async (
  createPayload: CreateEntityRequestPayload
): Promise<EntityObj> => {
  const response = await axioService(
    POST,
    SERVER_ENTITY_ENDPOINT,
    {
      name: createPayload.name,
      type: createPayload.type,
      namespace_id: createPayload.namespaceId,
    },
    true
  )
  return mapEntityFromServer(response.data)
}

export const removeEntityInServer = async (
  payload: RemoveEntityRequestPayload
) => {
  const response = await axioService(
    DELETE,
    `${SERVER_ENTITY_ENDPOINT}/${payload.entityId}`,
    {},
    true
  )
  return response
}
