import { AddCircleOutline } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { AddRemoveEntityUserPayload } from '../../store/slices/models'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { useAppDispatch } from '../../store/hooks'

interface AddEntityUserButtonProps {
  userId: string
  userEmail: string
  entityId: string
  entityName: string
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>
  postActionHandler?: () => void
}

const AddEntityUserButton = ({
  userEmail,
  userId,
  entityAction,
  entityId,
  entityName,
  postActionHandler,
}: AddEntityUserButtonProps) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    dispatch(
      entityAction({
        userId,
        entityId,
        postSuccessHandler: () => {
          if (postActionHandler) postActionHandler()
          enqueueSnackbar(
            'User added successfully!',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          enqueueSnackbar('Failed to add user!', ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <AddCircleOutline color="secondary" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm the action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you really want to add ${userEmail} to ${entityName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddEntityUserButton
